import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Tag, Typography, Input, Select, Form } from 'antd';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { updateEmployee } from '../../../../../../network/network';
import NotificationWithIcon from '../../../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../../../components/ImageUpload';
const { Text } = Typography;
const { Option } = Select;

const EmployeeProfile = ({ employee, cardStyle }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [image, setImage] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        ...employee,
    });

    useEffect(() => {
        setEmployeeData({
            ...employee,
        });
    }, [employee])

    const handleEdit = () => {
        setEditMode(true);
        setImage(employeeData.image);
        editForm.setFieldsValue({
            id: employeeData.id,
            name: employeeData.name,
            email: employeeData.email,
            status: employeeData.status,
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Employee updated successfully`, `Employee updated Successfully`);
            editForm.resetFields();
            console.log(response.data)
            setEmployeeData({ ...response.data });
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = await editForm.validateFields();
            const formData = new window.FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined) {
                    formData.append(key, values[key]);
                } else if (Array.isArray(values[key])) {
                    values[key].forEach((tag, index) => {
                        formData.append(`${key}[${index}]`, tag);
                    });
                }
            });
            if (image && image instanceof File) {
                formData.append("image", image);
            }
            const response = await updateEmployee(formData, employee.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    return (
        <Card style={{ width: '100%', overflow: 'hidden', ...cardStyle }}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                {/* Top section with image and basic info */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '0px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {/* Image Column */}
                        <div style={{ marginRight: '15px' }}>
                            {editMode ? (
                                <ImageUpload
                                    name="employee_picture"
                                    callbackSetImageFile={setImage}
                                    image={image}
                                />
                            ) : (employeeData.image ? (
                                <img
                                    src={employeeData.image}
                                    alt="employee picture"
                                    style={{ width: '100px', padding: '10px 0px', borderRadius: '5%', border: '1px dashed #d9d9d9' }}
                                />
                            ) : (
                                <UserOutlined style={{ fontSize: '80px' }} />
                            ))}
                        </div>

                        {/* Basic Info Column */}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div>
                                <Text strong>Employee Name</Text>
                                {editMode ? (
                                    <Form.Item name={'name'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                        <Input style={{ width: 200 }} />
                                    </Form.Item>
                                ) : (
                                    <div style={{ marginTop: '5px' }}>{employeeData.name}</div>
                                )}
                            </div>

                            <div>
                                <Text strong>Email</Text>
                                {editMode ? (
                                    <Form.Item name={'email'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                        <Input style={{ width: 200 }} />
                                    </Form.Item>
                                ) : (
                                    <div style={{ marginTop: '5px' }}>{employeeData.email}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Edit/Save Buttons */}
                    <div>
                        {editMode ? (
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Button onClick={handleSave} type="primary">
                                    <SaveOutlined /> Save
                                </Button>
                                <Button onClick={handleCancel}>
                                    <CloseOutlined /> Cancel
                                </Button>
                            </div>
                        ) : (
                            <Button onClick={handleEdit} type="primary">
                                <EditOutlined /> Edit Employee
                            </Button>
                        )}
                    </div>
                </div>

                <Divider style={{ margin: '20px 0' }} />

                {/* Details Grid */}
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
                    <div>
                        <Text strong>Status</Text>
                        <div style={{ marginTop: '5px' }}>
                            {editMode ? (
                                <Form.Item name={'status'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                    <Select style={{ width: '100%' }}>
                                        <Option value="active">Active</Option>
                                        <Option value="inactive">Inactive</Option>
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Tag color={employeeData.status === 'Active' ? 'green' : 'volcano'} style={{ textTransform: 'capitalize' }}>
                                    {employeeData.status}
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default EmployeeProfile;
