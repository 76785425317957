import React from 'react';
import { Card, Row, Col, Statistic, Progress, Tooltip } from 'antd';
import {
  DashboardOutlined,
  ClockCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';

const performanceData = [
  {
    title: 'System Uptime',
    value: '99.9%',
    icon: <DashboardOutlined />,
    tooltip: 'Percentage of time the system has been operational.',
    progress: 99.9,
  },
  {
    title: 'Average Response Time',
    value: '250 ms',
    icon: <ClockCircleOutlined />,
    tooltip: 'Average time taken to respond to user requests.',
    progress: 25,
  },
  {
    title: 'Active Alerts',
    value: 5,
    icon: <WarningOutlined />,
    tooltip: 'Number of active system alerts.',
    progress: 100,
  },
];

function PerformanceOverview() {
  return (
    <Card title="Performance Overview" bordered>
      <Row gutter={[16, 16]}>
        {performanceData.map((item) => (
          <Col xs={24} sm={12} md={8} key={item.title}>
            <Tooltip title={item.tooltip} placement="topLeft">
              <Statistic
                title={
                  <span>
                    {item.title}
                  </span>
                }
                value={item.value}
                prefix={<span style={{ fontSize: '24px', color: '#1890ff' }}>{item.icon}</span>}
                style={{ marginBottom: '16px' }}
              />
            </Tooltip>
            <Progress
              percent={item.progress}
              status={item.progress >= 80 ? 'exception' : item.progress >= 50 ? 'active' : 'normal'}
              showInfo={false}
              strokeColor={
                item.progress >= 80 ? '#f5222d' :
                item.progress >= 50 ? '#faad14' :
                '#52c41a'
              }
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default PerformanceOverview;
