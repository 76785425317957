import React from 'react';
import { Card, List, Avatar } from 'antd';
import {
  FilePdfOutlined,
  FileExcelOutlined,
  FileWordOutlined,
} from '@ant-design/icons';

const documents = [
  { title: 'Contract - Acme Corp', type: 'pdf', updatedAt: '2023-06-10' },
  { title: 'Invoice - Beta Industries', type: 'excel', updatedAt: '2023-06-09' },
  { title: 'Agreement - Gamma Logistics', type: 'doc', updatedAt: '2023-06-08' },
];

const getIcon = (type) => {
  switch (type) {
    case 'pdf':
      return <FilePdfOutlined style={{ color: '#ff4d4f', fontSize: '24px' }} />;
    case 'excel':
      return <FileExcelOutlined style={{ color: '#52c41a', fontSize: '24px' }} />;
    case 'doc':
      return <FileWordOutlined style={{ color: '#1890ff', fontSize: '24px' }} />;
    default:
      return <FilePdfOutlined style={{ color: '#d9d9d9', fontSize: '24px' }} />;
  }
};

function RecentDocuments() {
  return (
    <Card title="Recent Documents">
      <List
        itemLayout="horizontal"
        dataSource={documents}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar>{getIcon(item.type)}</Avatar>}
              title={<a href={`/${item.type}/${item.title.replace(/\s+/g, '-')}`}>{item.title}</a>}
              description={`Updated: ${item.updatedAt}`}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default RecentDocuments;
