import React from 'react';
import { Row, Col, Divider } from 'antd';
import KPISection from './KPISection';
import ChartsSection from './ChartsSection';
import RecentActivities from './RecentActivities';
import MaintenanceAlerts from './MaintenanceAlerts';
import QuickAccessPanel from './QuickAccessPanel';
import GeographicalOverview from './GeographicalOverview';
import ServiceOverview from './ServiceOverview';
import RecentDocuments from './RecentDocuments';
import UserManagementOverview from './UserManagementOverview';
import PerformanceOverview from './PerformanceOverview';
import NotificationPanel from './NotificationPanel';
import RevenueOverview from './RevenueOverview';
import TaskAssignments from './TaskAssignments';

function DashboardContent() {
  return (
    <div>
      <KPISection />
      <Divider />
      <PerformanceOverview />
      <Divider />
      <RevenueOverview />
      <Divider />
      <NotificationPanel />
      <Divider />
      <ChartsSection />
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <RecentActivities />
        </Col>
        <Col xs={24} lg={12}>
          <MaintenanceAlerts />
        </Col>
      </Row>
      <Divider />
      <QuickAccessPanel />
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <GeographicalOverview />
        </Col>
        <Col xs={24} lg={12}>
          <ServiceOverview />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <RecentDocuments />
        </Col>
        <Col xs={24} lg={12}>
          <UserManagementOverview />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <TaskAssignments />
        </Col>
        <Col xs={24} lg={12}>
          {/* Placeholder for any additional widget */}
        </Col>
      </Row>
    </div>
  );
}

export default DashboardContent;
