import React from 'react';
import { Card, Row, Col, Statistic, Tooltip } from 'antd';
import { DollarOutlined, BarChartOutlined, PieChartOutlined } from '@ant-design/icons';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';

const revenueData = [
  { month: 'Jan', revenue: 5000 },
  { month: 'Feb', revenue: 7000 },
  { month: 'Mar', revenue: 8000 },
  { month: 'Apr', revenue: 6000 },
  { month: 'May', revenue: 7500 },
  { month: 'Jun', revenue: 8500 },
  { month: 'Jul', revenue: 9000 },
];

const serviceRevenueData = [
  { name: 'Freight Delivery', value: 40000 },
  { name: 'Warehousing', value: 25000 },
  { name: 'Last Mile Delivery', value: 15000 },
  { name: 'Cross-border Logistics', value: 20000 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function RevenueOverview() {
  return (
    <Card title="Revenue Overview" bordered>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Tooltip title="Monthly Revenue">
            <Statistic
              prefix={<DollarOutlined />}
              title="Total Revenue"
              value={54000}
              valueStyle={{ color: '#3f8600' }}
            />
          </Tooltip>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={revenueData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey="revenue" stroke="#8884d8" fillOpacity={1} fill="url(#colorRevenue)" />
            </AreaChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={24} lg={12}>
          <Tooltip title="Revenue by Service">
            <Statistic
              prefix={<BarChartOutlined />}
              title="Revenue by Service"
              value={54000}
              valueStyle={{ color: '#cf1322' }}
            />
          </Tooltip>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={serviceRevenueData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
              >
                {serviceRevenueData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Card>
  );
}

export default RevenueOverview;
