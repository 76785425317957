import "./Home.css";
import { useContext } from "react";
import { Col, Row, Card, Layout } from "antd";

import { AppContext } from "../../../context/AppContextProvider";
import { Content } from "antd/es/layout/layout";
import DashboardContent from "../../Dashboard/components/DashboardContent";

const Home = () => {
  const { authUser } = useContext(AppContext);

  return (
    // <>
    //   <Row style={{ marginBottom: 16 }} gutter={16}>
    //     <Col span={24}>
    //       <Card className="fade-in-from-left">
    //         <h3>{"Hi " + authUser.name + " 👋, Welcome to Truckala!"}</h3>
    //       </Card>
    //     </Col>
    //   </Row>
    // </>
        <Content style={{ margin: '24px 16px 0' }}>
          <DashboardContent />
        </Content>
  );
};

export default Home;
