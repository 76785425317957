import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Space, Table, Typography, Row, Col, Descriptions, Tag, Divider, List } from 'antd';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, TruckFilled, EyeOutlined } from '@ant-design/icons';
import { addTrailerType, deleteTrailerType, getLoadTypes, getTrailerTypes, updateTrailerType, showTrailerType } from '../../../../network/network';
import NotificationWithIcon from '../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../components/ImageUpload';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const STATUS_OPTIONS = [
    { label: 'Active', value: 'Active', color: '#52c41a' },
    { label: 'Inactive', value: 'Inactive', color: '#ff4d4f' }
];

const TrailerTypeForm = ({ form, loadTypes, image, setImage }) => (
    <Form form={form} layout="vertical" requiredMark="optional">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <ImageUpload
                name="image"
                callbackSetImageFile={setImage}
                image={image}
            />

            <Form.Item
                name="name"
                label="Trailer Type Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input placeholder="Enter trailer type name" />
            </Form.Item>

            <Form.Item
                name="load_type_id"
                label="Load Type"
                rules={[{ required: true, message: 'Load type is required' }]}
            >
                <Select placeholder="Select load type">
                    {loadTypes?.map(type => (
                        <Option key={type.id} value={type.id}>
                            {type.type}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Space size="middle" style={{ width: '100%' }}>
                <Form.Item
                    name="capacity"
                    label="Capacity"
                    style={{ width: '100%' }}
                >
                    <Input placeholder="Enter capacity" />
                </Form.Item>

                <Form.Item
                    name="weight"
                    label="Weight"
                    style={{ width: '100%' }}
                >
                    <Input placeholder="Enter weight" />
                </Form.Item>
            </Space>

            <Form.Item
                name="status"
                label="Status"
                initialValue="Active"
            >
                <Select>
                    {STATUS_OPTIONS.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Space>
    </Form>
);

const TrailerTypes = () => {
    const [form] = Form.useForm();
    const [trailers, setTrailers] = useState([]);
    const [loadTypes, setLoadTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [modalConfig, setModalConfig] = useState({
        visible: false,
        title: '',
        editingId: null
    });
    const [selectedTrailer, setSelectedTrailer] = useState(null);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const navigate = useNavigate();

    const fetchTrailers = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getTrailerTypes();
            setTrailers(response?.data?.data?.trailer_types || []);
        } catch (error) {
            NotificationWithIcon(
                'error',
                'Error',
                'Failed to fetch trailer types. Please try again.'
            );
            setTrailers([]);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchLoadTypes = useCallback(async () => {
        try {
            const response = await getLoadTypes();
            setLoadTypes(response?.data?.data?.loadTypes || []);
        } catch (error) {
            NotificationWithIcon(
                'error',
                'Error',
                'Failed to fetch load types. Please try again.'
            );
            setLoadTypes([]);
        }
    }, []);

    useEffect(() => {
        fetchTrailers();
        fetchLoadTypes();
    }, [fetchTrailers, fetchLoadTypes]);

    const handleAdd = () => {
        form.resetFields();
        setImage(null);
        setModalConfig({
            visible: true,
            title: 'Add New Trailer Type',
            editingId: null
        });
    };

    const handleEdit = (record) => {
        form.setFieldsValue(record);
        setImage(record.image);
        setModalConfig({
            visible: true,
            title: 'Edit Trailer Type',
            editingId: record.id
        });
    };

    const handleDelete = async (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this trailer type?',
            content: 'This action cannot be undone.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, keep it',
            onOk: async () => {
                try {
                    await deleteTrailerType(id);
                    NotificationWithIcon(
                        'success',
                        'Success',
                        'Trailer type deleted successfully'
                    );
                    fetchTrailers();
                } catch (error) {
                    NotificationWithIcon(
                        'error',
                        'Error',
                        'Failed to delete trailer type. Please try again.'
                    );
                }
            }
        });
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const { editingId } = modalConfig;

            const formData = new FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined) {
                    formData.append(key, values[key]);
                }
            });

            if (image && image instanceof File) {
                formData.append('image', image);
            }

            let response;
            if (editingId) {
                response = await updateTrailerType(formData, editingId);
            } else {
                response = await addTrailerType(formData);
            }

            // Check if the response contains errors
            if (response?.response?.data?.errors) {
                // Handle validation errors
                const errorMessages = Object.values(response?.response?.data?.errors)
                    .flat()
                    .join(', ');

                NotificationWithIcon(
                    'error',
                    'Validation Error',
                    errorMessages
                );
                return; // Don't close modal or reset form
            }

            // If no errors, proceed with success
            NotificationWithIcon(
                'success',
                'Success',
                editingId
                    ? 'Trailer type updated successfully'
                    : 'Trailer type added successfully'
            );

            setModalConfig({ visible: false, title: '', editingId: null });
            form.resetFields();
            setImage(null);
            fetchTrailers();
        } catch (error) {
            NotificationWithIcon(
                'error',
                'Error',
                error?.response?.data?.message || 'Failed to save trailer type. Please try again.'
            );
        }
    };

    const handleView = async (id) => {
        try {
            const response = await showTrailerType(id);
            setSelectedTrailer(response?.data?.data);
            setViewModalVisible(true);
        } catch (error) {
            NotificationWithIcon(
                'error',
                'Error',
                'Failed to fetch trailer type details'
            );
        }
    };

    const handleFleetClick = (fleetId) => {
        navigate(`/fleets/${fleetId}`);
    };

    const columns = [
        {
            title: 'Image',
            align: 'center',
            width: '100px',
            render: (record) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {record.image ? (
                        <img
                            src={record.image}
                            alt="trailer type"
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                        />
                    ) : (
                        <TruckFilled style={{ fontSize: '40px' }} />
                    )}
                </div>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: 'Load Type',
            dataIndex: 'load_type',
            render: loadType => loadType?.type
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity'
        },
        {
            title: 'Weight',
            dataIndex: 'weight'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: status => {
                const statusOption = STATUS_OPTIONS.find(opt => opt.value === status);
                return (
                    <span style={{
                        color: statusOption?.color,
                        fontWeight: 500
                    }}>
                        {status}
                    </span>
                );
            }
        },
        {
            title: 'Actions',
            align: 'center',
            width: '150px',
            render: (record) => (
                <Space>
                    <Button
                        type="link"
                        onClick={() => handleView(record.id)}
                        title="View details"
                        style={{
                            padding: 6,
                            fontSize: "18px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(24, 144, 255, 0.2)",
                        }}
                    >
                        <EyeOutlined />
                    </Button>
                    <Button
                        type="link"
                        onClick={() => handleEdit(record)}
                        title="Edit record"
                        style={{
                            padding: 6,
                            fontSize: "18px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(24, 144, 255, 0.2)",
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        type="link"
                        onClick={() => handleDelete(record.id)}
                        title="Delete record"
                        style={{
                            padding: 6,
                            fontSize: "18px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <>
            <Card>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Title level={4} style={{ margin: 0 }}>
                            Trailer Types
                        </Title>
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={handleAdd}
                        >
                            Add Trailer Type
                        </Button>
                    </Space>

                    <Table
                        columns={columns}
                        dataSource={Array.isArray(trailers) ? trailers : []}
                        rowKey="id"
                        loading={loading}
                        // pagination={{
                        //     pageSize: 10,
                        //     showSizeChanger: true,
                        //     showTotal: (total) => `Total ${total} items`
                        // }}
                    />

                    <Modal
                        title={modalConfig.title}
                        open={modalConfig.visible}
                        onOk={handleModalSubmit}
                        onCancel={() => {
                            setModalConfig({ visible: false, title: '', editingId: null });
                            form.resetFields();
                            setImage(null);
                        }}
                        width={600}
                        destroyOnClose
                    >
                        <TrailerTypeForm
                            form={form}
                            loadTypes={loadTypes}
                            image={image}
                            setImage={setImage}
                        />
                    </Modal>
                </Space>
            </Card>

            {/* View Modal */}
            <Modal
                open={viewModalVisible}
                title="Trailer Type Details"
                onCancel={() => setViewModalVisible(false)}
                footer={null}
                width={800}
            >
                {selectedTrailer && (
                    <div>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                                    {selectedTrailer.image ? (
                                        <img
                                            src={selectedTrailer.image}
                                            alt="trailer type"
                                            style={{
                                                width: '200px',
                                                height: '200px',
                                                objectFit: 'cover',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    ) : (
                                        <TruckFilled style={{ fontSize: '100px' }} />
                                    )}
                                </div>
                            </Col>
                            <Col span={12}>
                                <Descriptions column={1}>
                                    <Descriptions.Item label="Name">{selectedTrailer.name}</Descriptions.Item>
                                    <Descriptions.Item label="Load Type">{selectedTrailer.load_type?.type}</Descriptions.Item>
                                    <Descriptions.Item label="Capacity">{selectedTrailer.capacity}</Descriptions.Item>
                                    <Descriptions.Item label="Weight">{selectedTrailer.weight}</Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        <Tag color={selectedTrailer.status === 'Active' ? '#52c41a' : '#ff4d4f'}>
                                            {selectedTrailer.status}
                                        </Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>

                        {selectedTrailer.fleets?.length > 0 && (
                            <>
                                <Divider orientation="left">Associated Fleets</Divider>
                                <List
                                    grid={{ gutter: 16, column: 2 }}
                                    dataSource={selectedTrailer.fleets}
                                    renderItem={fleet => (
                                        <List.Item>
                                            <Card
                                                hoverable
                                                onClick={() => handleFleetClick(fleet.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Card.Meta
                                                    avatar={
                                                        fleet.image ? (
                                                            <img
                                                                src={fleet.image}
                                                                alt={fleet.make?.title}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '50%'
                                                                }}
                                                            />
                                                        ) : (
                                                            <TruckFilled style={{ fontSize: '40px' }} />
                                                        )
                                                    }
                                                    title={`${fleet.make} (${fleet.license_plate})`}
                                                />
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default TrailerTypes;
