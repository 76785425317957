import React, { useState, useCallback, useEffect } from 'react';
import { Card, Table, Tabs, Tag } from 'antd';
import NotificationWithIcon from '../../../../../components/NotificationWithIcon';
import JobOrderStatus from '../../../../../constants/JobOrderStatus';
import MultiTripJobOrderStatus from '../../../../../constants/MultiTripJobOrderStatus';
import LowPriorityIcon from '../../../../../icons/LowPriorityIcon';
import MediumPriorityIcon from '../../../../../icons/MediumPriorityIcon';
import HighPriorityIcon from '../../../../../icons/HighPriorityIcon';
import { LinkOutlined } from '@ant-design/icons';
import { getMultiTripJobOrders, getSingleTripJobOrders } from '../../../../../network/network';
import JobOrderType from '../../../../../constants/JobOrderType';

const CustomerOrders = ({ customerId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [singleTripOrders, setSingleTripOrders] = useState([]);
    const [multiTripOrders, setMultiTripOrders] = useState([]);
    const [singleTripPagination, setSingleTripPagination] = useState({});
    const [multiTripPagination, setMultiTripPagination] = useState({});

    const fetchOrders = useCallback(async (page = 1, type = 'single') => {
        setIsLoading(true);
        try {
            if (type === 'single') {
                const response = await getSingleTripJobOrders({
                    customer_id: customerId,
                    job_order_type: JobOrderType.SINGLE_TRIP,
                    page: page,
                });
                const { job_orders, pagination } = response.data.data;

                setSingleTripOrders(job_orders);
                setSingleTripPagination({
                    current: pagination.current_page,
                    pageSize: pagination.per_page,
                    total: pagination.total,
                    showSizeChanger: false,
                });
            } else {
                const response = await getMultiTripJobOrders({
                    customer_id: customerId,
                    page: page,
                });
                const { multi_trip_job_orders, pagination } = response.data.data;

                setMultiTripOrders(multi_trip_job_orders);
                setMultiTripPagination({
                    current: pagination.current_page,
                    pageSize: pagination.per_page,
                    total: pagination.total,
                    showSizeChanger: false,
                });
            }
        } catch (error) {
            NotificationWithIcon('error', 'Error', 'Failed to fetch orders');
        } finally {
            setIsLoading(false);
        }
    }, [customerId]);

    useEffect(() => {
        fetchOrders(1, 'single');
        fetchOrders(1, 'multi');
    }, [fetchOrders]);

    const getMultiTripStatusColor = (statusName) => {
        console.log('status: ', statusName)
        switch (statusName) {
            case MultiTripJobOrderStatus.DRAFT:
                return 'gold';
            case MultiTripJobOrderStatus.OPEN:
                return 'blue';
            case MultiTripJobOrderStatus.COMPLETED:
                return 'green';
            case MultiTripJobOrderStatus.READY_FOR_INVOICE:
                return 'cyan';
            case MultiTripJobOrderStatus.INVOICE_ISSUED:
                return 'purple';
            default:
                return 'default';
        }
    };

    // Get Status Color
    const getSingleTripStatusColor = (statusName) => {
        switch (statusName) {
            case JobOrderStatus.DRAFT:
                return "gold";
            case JobOrderStatus.WITH_DISPATCHER:
                return "blue";
            case JobOrderStatus.WAITING_FOR_DRIVER_ACCEPTANCE:
                return "orange";
            case JobOrderStatus.WAYBILL_GENERATED:
                return "teal";
            case JobOrderStatus.IN_TRANSIT:
                return "green";
            case JobOrderStatus.POD_REQUESTED:
                return "purple";
            case JobOrderStatus.DELIVERED:
                return "lime";
            case JobOrderStatus.INVOICE_ISSUED:
                return "red";
            default:
                return "gray";
        }
    };

    const singleTripColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return (singleTripPagination.current - 1) * singleTripPagination.pageSize + index + 1;
            },
        },
        {
            title: "Reference No.",
            dataIndex: "reference_number",
        },
        {
            title: "Customer Name",
            render: (record) => (
                <>{record.customer ? record.customer.company_name : "-"}</>
            ),
        },
        {
            title: "Contract",
            render: (record) => (
                <>
                    {record.customer_contract
                        ? record.customer_contract.contract_name
                        : "-"}
                </>
            ),
        },
        {
            title: "Detention Date",
            dataIndex: "detention_date",
        },
        {
            title: "Delivery Date",
            dataIndex: "delivery_date",
        },
        {
            title: "Priority",
            dataIndex: "priority",
            render: (priority) => (
                <span style={{ display: "flex", alignItems: "center" }}>
                    {priority === "Low" && <LowPriorityIcon width={'24px'} height={'24px'} style={{ marginRight: 8 }} />}
                    {priority === "Medium" && <MediumPriorityIcon width={'24px'} height={'24px'} style={{ marginRight: 8 }} />}
                    {priority === "High" && <HighPriorityIcon width={'24px'} height={'24px'} style={{ marginRight: 8 }} />}
                    {priority}
                </span>
            ),
        },
        {
            title: "Status",
            key: "statusColor",
            width: "5px",
            render: (record) => {
                return (
                    <Tag bordered={false} color={getSingleTripStatusColor(record.status)}>
                        {record.status}
                    </Tag>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <a
                    onClick={() => {
                        const baseUrl = window.location.origin;
                        const path = `/single/trips?id=${record.id}`;
                        window.open(baseUrl + path, "_blank");
                    }}
                    style={{
                        padding: "4px 12px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        backgroundColor: "#f6ffed",
                        border: "1px solid #b7eb8f",
                        color: "#52c41a",
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "6px",
                        cursor: "pointer",
                        textDecoration: "none",
                        transition: "all 0.3s"
                    }}
                    onMouseEnter={e => {
                        e.currentTarget.style.backgroundColor = "#d9f7be";
                        e.currentTarget.style.borderColor = "#95de64";
                    }}
                    onMouseLeave={e => {
                        e.currentTarget.style.backgroundColor = "#f6ffed";
                        e.currentTarget.style.borderColor = "#b7eb8f";
                    }}
                >
                    <LinkOutlined />
                    <span>View Details</span>
                </a>
            ),
        },
    ];

    const multiTripColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return (
                    (multiTripPagination.current - 1) * multiTripPagination.pageSize + index + 1
                );
            },
        },
        {
            title: "Reference No.",
            dataIndex: "reference_number",
        },
        {
            title: "Customer Name",
            dataIndex: ["customer", "company_name"],
            key: "customerName",
            render: (text) => text || "-",
        },
        {
            title: "Trips",
            key: "singleTripsCount",
            width: "10%",
            render: (record) => (
                <Tag
                    color={record.single_trip_job_orders?.length ? "blue" : "red"}
                >
                    {record.single_trip_job_orders?.length || 0}
                </Tag>
            ),
        },
        {
            title: "Contract",
            dataIndex: ["customer_contract", "contract_name"],
            key: "contract",
            render: (text) => text || "-",
        },
        {
            title: "Status",
            key: "statusColor",
            width: "10%",
            render: (record) => (
                <Tag color={getMultiTripStatusColor(record.status)}>{record.status}</Tag>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <a
                    onClick={() => {
                        const baseUrl = window.location.origin;
                        const path = `/multi/trips?id=${record.id}`;
                        window.open(baseUrl + path, "_blank");
                    }}
                    style={{
                        padding: "4px 12px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        backgroundColor: "#f6ffed",
                        border: "1px solid #b7eb8f",
                        color: "#52c41a",
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "6px",
                        cursor: "pointer",
                        textDecoration: "none",
                        transition: "all 0.3s"
                    }}
                    onMouseEnter={e => {
                        e.currentTarget.style.backgroundColor = "#d9f7be";
                        e.currentTarget.style.borderColor = "#95de64";
                    }}
                    onMouseLeave={e => {
                        e.currentTarget.style.backgroundColor = "#f6ffed";
                        e.currentTarget.style.borderColor = "#b7eb8f";
                    }}
                >
                    <LinkOutlined />
                    <span>View Details</span>
                </a>
            ),
        },
    ];

    const items = [
        {
            key: '1',
            label: (
                <span>
                    Single Trip Orders
                    <Tag
                        style={{
                            marginLeft: '8px',
                            borderRadius: '10px',
                            fontSize: '12px',
                            padding: '0 8px',
                            backgroundColor: '#e6f4ff',
                            color: '#1890ff',
                            border: '1px solid #91caff'
                        }}
                    >
                        {singleTripPagination.total || 0}
                    </Tag>
                </span>
            ),
            children: (
                <Table
                    columns={singleTripColumns}
                    dataSource={singleTripOrders}
                    loading={isLoading}
                    pagination={singleTripPagination}
                    onChange={(pagination) => fetchOrders(pagination.current, 'single')}
                    rowKey="id"
                />
            ),
        },
        {
            key: '2',
            label: (
                <span>
                    Multi Trip Orders
                    <Tag
                        style={{
                            marginLeft: '8px',
                            borderRadius: '10px',
                            fontSize: '12px',
                            padding: '0 8px',
                            backgroundColor: '#e6f4ff',
                            color: '#1890ff',
                            border: '1px solid #91caff'
                        }}
                    >
                        {multiTripPagination.total || 0}
                    </Tag>
                </span>
            ),
            children: (
                <Table
                    columns={multiTripColumns}
                    dataSource={multiTripOrders}
                    loading={isLoading}
                    pagination={multiTripPagination}
                    onChange={(pagination) => fetchOrders(pagination.current, 'multi')}
                    rowKey="id"
                />
            ),
        },
    ];

    return (
        <Card>
            <Tabs items={items} />
        </Card>
    );
};

export default CustomerOrders;
