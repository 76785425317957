import React from 'react';
import { Card, List, Avatar, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const users = [
  { name: 'John Doe', role: 'Admin', status: 'online' },
  { name: 'Jane Smith', role: 'Manager', status: 'offline' },
  { name: 'Bob Johnson', role: 'Dispatcher', status: 'online' },
  { name: 'Alice Williams', role: 'Driver', status: 'offline' },
];

function UserManagementOverview() {
  return (
    <Card title="User Management Overview">
      <List
        itemLayout="horizontal"
        dataSource={users}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Badge
                  dot
                  status={item.status === 'online' ? 'success' : 'default'}
                  offset={[0, 20]}
                >
                  <Avatar icon={<UserOutlined />} />
                </Badge>
              }
              title={item.name}
              description={item.role}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default UserManagementOverview;
