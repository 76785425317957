import React from 'react';
import { Card, Table, Tag } from 'antd';

const columns = [
  {
    title: 'Order ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id.localeCompare(b.id),
    render: (text) => <a href={`/job-orders/${text}`}>{text}</a>,
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    sorter: (a, b) => a.customer.localeCompare(b.customer),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    filters: [
      { text: 'Multi-trip', value: 'Multi-trip' },
      { text: 'Single-trip', value: 'Single-trip' },
    ],
    onFilter: (value, record) => record.type === value,
    render: (type) => (
      <Tag color={type === 'Multi-trip' ? 'blue' : 'green'}>
        {type}
      </Tag>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: [
      { text: 'Completed', value: 'Completed' },
      { text: 'In Progress', value: 'In Progress' },
      { text: 'Pending', value: 'Pending' },
    ],
    onFilter: (value, record) => record.status === value,
    render: (status) => {
      let color = 'geekblue';
      if (status === 'Completed') {
        color = 'green';
      } else if (status === 'In Progress') {
        color = 'orange';
      } else if (status === 'Pending') {
        color = 'volcano';
      }
      return <Tag color={color}>{status}</Tag>;
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
  },
];

const data = [
  {
    key: '1',
    id: 'JO-001',
    customer: 'Acme Corp',
    type: 'Multi-trip',
    status: 'Completed',
    date: '2023-06-10',
  },
  {
    key: '2',
    id: 'JO-002',
    customer: 'Beta Industries',
    type: 'Single-trip',
    status: 'In Progress',
    date: '2023-06-09',
  },
  {
    key: '3',
    id: 'JO-003',
    customer: 'Gamma Logistics',
    type: 'Multi-trip',
    status: 'Pending',
    date: '2023-06-08',
  },
  {
    key: '4',
    id: 'JO-004',
    customer: 'Delta Services',
    type: 'Single-trip',
    status: 'Completed',
    date: '2023-06-07',
  },
  {
    key: '5',
    id: 'JO-005',
    customer: 'Epsilon Enterprises',
    type: 'Multi-trip',
    status: 'In Progress',
    date: '2023-06-06',
  },
];

function RecentActivities() {
  return (
    <Card title="Recent Job Orders">
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        rowKey="id"
        size="small"
        bordered
      />
    </Card>
  );
}

export default RecentActivities;
