import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    Form,
    Button,
    Drawer,
    Row,
    Space,
    Tabs,
    Popconfirm,
    Input,
    Tag,
    Col,
    Select,
    Checkbox,
    DatePicker,
    Collapse,
    Table,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
    addMultiTripJobOrder,
    changeMultiTripJobOrderStatus,
    deleteMultiTripJobOrder,
    getCustomerContractsByCustomerId,
    getMultiTripJobOrders,
    rawDataMultiJobOrder,
    updateMultiTripJobOrder,
    getTransportationsByContractId,
    changeJobOrderStatus,
    getMultiTripJobOrder,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
    DeleteTwoTone,
    EditTwoTone,
    EyeFilled,
    FileFilled,
    InfoCircleFilled,
    LinkOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { debounce } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import Documents from "../Documents";
import { default as DocumentsGeneric } from "../../../../components/Documents";
import MultiTripJobOrderStatus from "../../../../constants/MultiTripJobOrderStatus";
import Mate from "../../../../components/Mate/Mate";
import LowPriorityIcon from "../../../../icons/LowPriorityIcon";
import MediumPriorityIcon from "../../../../icons/MediumPriorityIcon";
import HighPriorityIcon from "../../../../icons/HighPriorityIcon";
import JobOrderStatus from "../../../../constants/JobOrderStatus";
import JobOrderType from "../../../../constants/JobOrderType";

dayjs.extend(utc);
const { Panel } = Collapse;

const MultiTripJobOrder = () => {
    const { multiTripJobOrderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [multiTripJobOrdersAllData, setMultiTripJobOrdersAllData] = useState(
        []
    );
    const [multiTripJobOrdersRawData, setMultiTripJobOrdersRawData] = useState(
        []
    );
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTabKey, setActiveTabKey] = useState("1");
    const [selectedMultiTripJobOrder, setSelectedMultiTripJobOrder] =
        useState(null);
    const [operationMode, setOperationMode] = useState("");
    const [contracts, setContracts] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    const [multiTripJobOrdersForm] = Form.useForm();
    const [transportations, setTransportations] = useState([]);
    const [fromLocations, setFromLocations] = useState([]);
    const [toLocations, setToLocations] = useState([]);
    const [loadTypes, setLoadTypes] = useState([]);
    const [fleetTypes, setFleetTypes] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState({});
    const [activePanels, setActivePanels] = useState([]);
    const [searchParams] = useSearchParams();

    // Helper Functions
    const getUniqueOptions = (field, transportationsData = transportations) => {
        return Array.from(
            new Set(transportationsData.map((t) => t[field]?.id))
        ).map((id) => transportationsData.find((t) => t[field]?.id === id)[field]);
    };

    const getFilteredOptions = (
        field,
        fromLocation,
        toLocation = null,
        loadType = null,
        transportationsData = transportations
    ) => {
        return Array.from(
            new Set(
                transportationsData
                    .filter(
                        (t) =>
                            t.from_location.id === parseInt(fromLocation) &&
                            (!toLocation || t.to_location.id === parseInt(toLocation)) &&
                            (!loadType || t.load_type.id === parseInt(loadType))
                    )
                    .map((t) => t[field]?.id)
            )
        )
            .map((id) => transportationsData.find((t) => t[field]?.id === id)[field])
            .filter(Boolean); // Ensure no undefined values
    };

    // Fetch raw data on component mount
    useEffect(() => {
        const fetchRawData = async () => {
            try {
                const rawDataResponse = await rawDataMultiJobOrder();
                setMultiTripJobOrdersRawData(rawDataResponse.data.data);
                setFromLocations(rawDataResponse.data.data.from_locations);
                setToLocations(rawDataResponse.data.data.to_locations);
                setLoadTypes(rawDataResponse.data.data.load_types);
                setFleetTypes(rawDataResponse.data.data.fleet_types);
            } catch (error) {
                console.error("Fetching raw data failed:", error);
                NotificationWithIcon("error", "Error", "Failed to fetch initial data.");
            }
        };
        fetchRawData();
    }, []);

    // Fetch job orders data
    const fetchData = useCallback(async () => {
        setIsDataLoading(true);
        try {
            const jobOrdersResponse = await getMultiTripJobOrders({
                page: currentPage,
                search: searchTerm,
            });

            const jobOrdersData = jobOrdersResponse.data.data.multi_trip_job_orders;
            setMultiTripJobOrdersAllData(jobOrdersData);

            // Check for URL parameter after data is loaded
            const jobOrderId = searchParams.get('id');
            if (jobOrderId) {
                let jobOrder = jobOrdersData.find(
                    order => order.id === parseInt(jobOrderId)
                );

                if (!jobOrder) {
                    // If not found, fetch it separately
                    const singleOrderResponse = await getMultiTripJobOrder(jobOrderId);
                    jobOrder = singleOrderResponse.data.data;
                }

                if (jobOrder) {
                    showModal('View', jobOrder);
                }
            }

            const paginationData = jobOrdersResponse.data.data.pagination;
            setPagination({
                current: paginationData.current_page,
                pageSize: paginationData.per_page,
                total: paginationData.total,
                showSizeChanger: false,
            });
        } catch (error) {
            console.error("Fetching job orders failed:", error);
            NotificationWithIcon("error", "Error", "Failed to fetch job orders.");
        } finally {
            setIsDataLoading(false);
        }
    }, [currentPage, searchTerm, searchParams]);

    useEffect(() => {
        fetchData();
    }, [currentPage, fetchData]);

    // Handle search with debounce
    const debouncedFetchData = useCallback(
        debounce(() => {
            fetchData();
        }, 500),
        [fetchData]
    );

    useEffect(() => {
        debouncedFetchData();
        return () => debouncedFetchData.cancel();
    }, [searchTerm, debouncedFetchData]);

    // Handle cancellation of the drawer
    const handleCancel = () => {
        setDrawerVisible(false);
        setActiveTabKey("1");
        multiTripJobOrdersForm.resetFields();
        setFilteredOptions({});
        setSelectedContract(null);
        setDocuments([]);
        setDocumentsData([]);
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('id');
        window.history.replaceState({}, '', `${window.location.pathname}${newSearchParams.toString() ? '?' + newSearchParams.toString() : ''}`);
    };

    // Handle API responses
    const handleAPIResponse = (response, isAdding) => {
        if (response?.data && response?.data?.success === true) {
            NotificationWithIcon(
                "success",
                "Job Order",
                `Job Order ${isAdding ? "Added" : "Updated"} Successfully`
            );
            fetchData();
            handleCancel();
        } else if (response && response?.response?.data?.errors) {
            // Handle validation errors
            Object.values(response?.response?.data?.errors).forEach((errorArray) => {
                errorArray.forEach((errorMsg) => {
                    NotificationWithIcon("error", "Validation Error", errorMsg);
                });
            });
        } else {
            // Handle other types of errors
            NotificationWithIcon("error", "Something Went Wrong", "An unexpected error occurred.");
        }
    };

    // Handle Add/Edit submission
    const handleAddEdit = async () => {
        try {
            setLoading(true);
            const values = await multiTripJobOrdersForm.validateFields();
            const jobOrderObj = {
                ...values,
                id: selectedMultiTripJobOrder?.id,
                customer_contract_id: values.customer_contract_id,
            };

            const formData = new FormData();
            Object.keys(jobOrderObj).forEach((key) => {
                if (
                    jobOrderObj[key] !== undefined &&
                    key !== "single_trip_job_orders"
                ) {
                    formData.append(key, jobOrderObj[key]);
                }
            });
            console.log("submission: ", values);
            // Handle single trip job orders
            jobOrderObj.single_trip_job_orders?.forEach((order, index) => {
                formData.append(`single_trip_job_orders[${index}][id]`, order.id || "");
                formData.append(
                    `single_trip_job_orders[${index}][demurrage_date]`,
                    order.demurrage_date ? order.demurrage_date.format("YYYY-MM-DD") : ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][delivery_date]`,
                    order.delivery_date ? order.delivery_date.format("YYYY-MM-DD") : ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][detention_date]`,
                    order.detention_date ? order.detention_date.format("YYYY-MM-DD") : ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][priority]`,
                    order.priority || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][from_location_id]`,
                    order.from_location_id || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][to_location_id]`,
                    order.to_location_id || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][load_type_id]`,
                    order.load_type_id || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][fleet_type_id]`,
                    order.fleet_type_id || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][unit_price]`,
                    order.unit_price || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][tax]`,
                    order.tax || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][total_price]`,
                    order.total_price || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][transportation_id]`,
                    order.transportation_id || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][transportation_mode]`,
                    order.transportation_mode || "Internal"
                );
                formData.append(
                    `single_trip_job_orders[${index}][description]`,
                    order.description || ""
                );
                formData.append(
                    `single_trip_job_orders[${index}][job_order_type]`,
                    JobOrderType.MULTI_TRIP
                );
            });

            // Handle documents
            documents.forEach((item, index) => {
                if (item.title && item.document) {
                    formData.append(`documents[${index}][title]`, item.title);
                    formData.append(
                        `documents[${index}][expiry_date]`,
                        item.expiry_date ? dayjs(item.expiry_date).format("YYYY-MM-DD") : ""
                    );
                    formData.append(`documents[${index}][document]`, item.document.file);
                }
            });

            let response;
            if (operationMode === "Add") {
                response = await addMultiTripJobOrder(formData);
            } else {
                response = await updateMultiTripJobOrder(
                    formData,
                    selectedMultiTripJobOrder?.id
                );
            }
            handleAPIResponse(response, operationMode === "Add");
        } catch (error) {
            console.error("Validation or API call failed:", error);
            NotificationWithIcon("error", "Error", "Failed to submit the form.");
        } finally {
            setLoading(false);
        }
    };

    // Handle Delete
    const handleDelete = (id) => {
        deleteMultiTripJobOrder(id)
            .then((response) => {
                const data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Job Order Deleted", data.message);
                    fetchData();
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    // Handle Multi Trip Job Status Change
    const changeStatus = (id, status) => {
        changeMultiTripJobOrderStatus(id, { status: status })
            .then((response) => {
                const data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Job Order Updated", data.message);
                    handleCancel();
                    fetchData();
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    // Handle Single Trip Job Status Change
    const changeSingleTripStatus = (id, status) => {
        changeJobOrderStatus(id, { status: status })
            .then((response) => {
                const data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Job Order Updated", data.message);
                    handleCancel();
                    fetchData();
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    // Show Modal (Add/Edit/View)
    const showModal = async (mode, jobOrder = null) => {
        setSelectedMultiTripJobOrder(jobOrder);
        setOperationMode(mode);
        setLoading(false);
        setFilteredOptions({});

        if (mode === "Add") {
            multiTripJobOrdersForm.resetFields();
            setActivePanels([]); // Reset active panels for new form
        } else if (mode === "Edit" || mode === "View") {
            if (jobOrder) {
                console.log("Original jobOrder:", jobOrder); // Debug log

                // Create a properly structured single_trip_job_orders array
                const singleTripOrders = jobOrder.single_trip_job_orders.map(
                    (order, index) => {
                        console.log(`Processing order ${index}:`, order); // Debug log
                        console.log(`Load info for order ${index}:`, order.load_info); // Debug log

                        // Ensure we're getting the correct load_info entry
                        const loadInfo =
                            Array.isArray(order.load_info) && order.load_info.length > 0
                                ? order.load_info[0]
                                : {};

                        return {
                            id: order.id,
                            reference_number: order.reference_number,
                            demurrage_date: order.demurrage_date
                                ? dayjs(order.demurrage_date)
                                : null,
                            delivery_date: order.delivery_date
                                ? dayjs(order.delivery_date)
                                : null,
                            detention_date: order.detention_date
                                ? dayjs(order.detention_date)
                                : null,
                            priority: order.priority || "",
                            status: order.status || JobOrderStatus.DRAFT,
                            from_location_id: loadInfo?.from_location?.id || null,
                            to_location_id: loadInfo?.to_location?.id || null,
                            load_type_id: loadInfo?.load_type?.id || null,
                            fleet_type_id: loadInfo?.fleet_type?.id || null,
                            unit_price: loadInfo?.unit_price || null,
                            tax: loadInfo?.tax || null,
                            total_price: loadInfo?.total_price || null,
                            transportation_id: loadInfo?.transportation_id || null,
                            transportation_mode: loadInfo?.transportation_mode || "Internal",
                            description: loadInfo?.description || "",
                        };
                    }
                );

                console.log("Processed singleTripOrders:", singleTripOrders); // Debug log

                const initialFormValues = {
                    customer_id: jobOrder.customer?.id,
                    customer_contract_id: jobOrder.customer_contract?.id,
                    client_reference: jobOrder.client_reference,
                    bl_number: jobOrder.bl_number,
                    single_trip_job_orders: singleTripOrders,
                };

                console.log("Initial form values:", initialFormValues);

                setDocumentsData(jobOrder.documents || []);
                setSelectedContract(jobOrder.customer_contract_id);

                // Reset form and set values
                multiTripJobOrdersForm.resetFields();
                multiTripJobOrdersForm.setFieldsValue(initialFormValues);

                if (
                    initialFormValues.customer_contract_id
                ) {
                    try {
                        const contractsResponse = await getCustomerContractsByCustomerId(
                            initialFormValues.customer_id
                        );
                        setContracts(contractsResponse.data.data);

                        const transportationResponse = await getTransportationsByContractId(
                            initialFormValues.customer_contract_id
                        );
                        const transportData = transportationResponse.data.data;
                        setTransportations(transportData);

                        // Initialize filtered options for all existing orders
                        const options = {};
                        singleTripOrders.forEach((order, index) => {
                            options[index] = {
                                fromLocations: getUniqueOptions("from_location", transportData),
                                toLocations: order.from_location_id
                                    ? getFilteredOptions(
                                        "to_location",
                                        order.from_location_id,
                                        null,
                                        null,
                                        transportData
                                    )
                                    : [],
                                loadTypes:
                                    order.from_location_id && order.to_location_id
                                        ? getFilteredOptions(
                                            "load_type",
                                            order.from_location_id,
                                            order.to_location_id,
                                            null,
                                            transportData
                                        )
                                        : [],
                                fleetTypes:
                                    order.from_location_id &&
                                        order.to_location_id &&
                                        order.load_type_id
                                        ? getFilteredOptions(
                                            "fleet_type",
                                            order.from_location_id,
                                            order.to_location_id,
                                            order.load_type_id,
                                            transportData
                                        )
                                        : [],
                            };
                        });
                        setFilteredOptions(options);
                    } catch (error) {
                        console.error("Fetching contracts/transportations failed:", error);
                        NotificationWithIcon(
                            "error",
                            "Error",
                            "Failed to fetch contracts or transportations."
                        );
                    }
                }

                // Open all panels by setting their keys
                const panelKeys = jobOrder.single_trip_job_orders.map((_, index) =>
                    index.toString()
                );
                setActivePanels(panelKeys);
            }
        }

        setDrawerVisible(true);
    };

    // Initialize filtered options based on existing single trip job orders
    const initializeFilteredOptions = (transportationsData, singleTripOrders) => {
        const options = {};
        singleTripOrders?.forEach((order, index) => {
            options[index] = {
                fromLocations: getUniqueOptions("from_location", transportationsData),
                toLocations: order.from_location_id
                    ? getFilteredOptions(
                        "to_location",
                        order.from_location_id,
                        null,
                        null,
                        transportationsData
                    )
                    : [],
                loadTypes:
                    order.from_location_id && order.to_location_id
                        ? getFilteredOptions(
                            "load_type",
                            order.from_location_id,
                            order.to_location_id,
                            null,
                            transportationsData
                        )
                        : [],
                fleetTypes:
                    order.from_location_id && order.to_location_id && order.load_type_id
                        ? getFilteredOptions(
                            "fleet_type",
                            order.from_location_id,
                            order.to_location_id,
                            order.load_type_id,
                            transportationsData
                        )
                        : [],
            };
        });
        setFilteredOptions(options);
        console.log(options);
    };

    // Show Job Order Details (View Mode)
    const showJobOrderDetailsDrawer = (jobOrder) => {
        showModal("View", jobOrder);
    };

    // Handle Table Change (Pagination)
    const handleContractsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    // Handle Customer Change
    const onCustomerChange = (id) => {
        getCustomerContractsByCustomerId(id)
            .then((response) => {
                setContracts(response.data.data);
                if (selectedContract) {
                    setSelectedContract(null);
                    setTransportations([]);
                    setFilteredOptions({});
                    multiTripJobOrdersForm.setFieldsValue({
                        customer_contract_id: null,
                    });
                }
            })
            .catch((error) => {
                console.error("Fetching contracts failed:", error);
                NotificationWithIcon("error", "Error", "Failed to fetch contracts.");
            });
    };

    // Handle Contract Change
    const onContractChange = (id) => {
        setSelectedContract(id);
        if (id) {
            getTransportationsByContractId(id)
                .then((response) => {
                    setTransportations(response.data.data);
                    // Initialize filtered options based on transportations
                    initializeFilteredOptions(
                        multiTripJobOrdersForm.getFieldValue("single_trip_job_orders") || []
                    );
                    // Reset form fields related to single trip job orders
                    multiTripJobOrdersForm.setFieldsValue({
                        single_trip_job_orders:
                            multiTripJobOrdersForm.getFieldValue("single_trip_job_orders") ||
                            [],
                    });
                })
                .catch((error) => {
                    console.error("Fetching transportations failed:", error);
                    NotificationWithIcon(
                        "error",
                        "Error",
                        "Failed to fetch transportations."
                    );
                });
        } else {
            setTransportations([]);
            setFilteredOptions({});
        }
    };

    // Get Multi Trip Status Color
    const getMultiTripStatusColor = (statusName) => {
        console.log('status: ', statusName)
        switch (statusName) {
            case MultiTripJobOrderStatus.DRAFT:
                return 'gold';
            case MultiTripJobOrderStatus.OPEN:
                return 'blue';
            case MultiTripJobOrderStatus.COMPLETED:
                return 'green';
            case MultiTripJobOrderStatus.READY_FOR_INVOICE:
                return 'cyan';
            case MultiTripJobOrderStatus.INVOICE_ISSUED:
                return 'purple';
            default:
                return 'default';
        }
    };

    // Get Status Color
    const getStatusColor = (statusName) => {
        switch (statusName) {
            case JobOrderStatus.DRAFT:
                return "gold";
            case JobOrderStatus.WITH_DISPATCHER:
                return "blue";
            case JobOrderStatus.WAITING_FOR_DRIVER_ACCEPTANCE:
                return "orange";
            case JobOrderStatus.WAYBILL_GENERATED:
                return "teal";
            case JobOrderStatus.IN_TRANSIT:
                return "green";
            case JobOrderStatus.POD_REQUESTED:
                return "purple";
            case JobOrderStatus.DELIVERED:
                return "lime";
            case JobOrderStatus.INVOICE_ISSUED:
                return "red";
            default:
                return "gray";
        }
    };

    // Handle Single Trip Job Order Field Change
    const handleSingleTripFieldChange = (index, field, value) => {
        const currentValues = multiTripJobOrdersForm.getFieldsValue();
        const updatedOrders = currentValues.single_trip_job_orders.map(
            (order, idx) => {
                if (idx !== index) return order;

                // Create a new order object to maintain immutability
                const updatedOrder = { ...order };

                switch (field) {
                    case "from_location_id":
                        updatedOrder.from_location_id = value;
                        // Reset dependent fields
                        updatedOrder.to_location_id = null;
                        updatedOrder.load_type_id = null;
                        updatedOrder.fleet_type_id = null;
                        updatedOrder.transportation_id = null;
                        updatedOrder.unit_price = null;
                        updatedOrder.tax = null;
                        updatedOrder.total_price = null;
                        break;

                    case "to_location_id":
                        updatedOrder.to_location_id = value;
                        // Reset dependent fields
                        updatedOrder.load_type_id = null;
                        updatedOrder.fleet_type_id = null;
                        updatedOrder.transportation_id = null;
                        updatedOrder.unit_price = null;
                        updatedOrder.tax = null;
                        updatedOrder.total_price = null;
                        break;

                    case "load_type_id":
                        updatedOrder.load_type_id = value;
                        // Reset dependent fields
                        updatedOrder.fleet_type_id = null;
                        updatedOrder.transportation_id = null;
                        updatedOrder.unit_price = null;
                        updatedOrder.tax = null;
                        updatedOrder.total_price = null;
                        break;

                    case "fleet_type_id":
                        updatedOrder.fleet_type_id = value;
                        // Update unit price, tax, total price based on transportation
                        const selectedTransportation = transportations.find(
                            (t) =>
                                t.from_location.id ===
                                parseInt(updatedOrder.from_location_id) &&
                                t.to_location.id === parseInt(updatedOrder.to_location_id) &&
                                t.load_type.id === parseInt(updatedOrder.load_type_id) &&
                                t.fleet_type.id === parseInt(value)
                        );

                        if (selectedTransportation) {
                            updatedOrder.unit_price = parseFloat(
                                selectedTransportation.charges
                            ).toFixed(2);
                            updatedOrder.tax = (
                                parseFloat(updatedOrder.unit_price) * 0.15
                            ).toFixed(2);
                            updatedOrder.total_price = (
                                parseFloat(updatedOrder.unit_price) +
                                parseFloat(updatedOrder.tax)
                            ).toFixed(2);
                            updatedOrder.transportation_id = selectedTransportation.id;
                        } else {
                            updatedOrder.unit_price = null;
                            updatedOrder.tax = null;
                            updatedOrder.total_price = null;
                            updatedOrder.transportation_id = null;
                        }
                        break;

                    default:
                        break;
                }

                return updatedOrder;
            }
        );

        // Update the filtered options based on the changed field
        let updatedFilteredOptions = { ...filteredOptions };

        if (field === "from_location_id") {
            updatedFilteredOptions[index] = {
                ...updatedFilteredOptions[index],
                toLocations: getFilteredOptions("to_location", value),
                loadTypes: [],
                fleetTypes: [],
            };
        } else if (field === "to_location_id") {
            const currentOrder = updatedOrders[index];
            updatedFilteredOptions[index] = {
                ...updatedFilteredOptions[index],
                loadTypes: getFilteredOptions(
                    "load_type",
                    currentOrder.from_location_id,
                    value
                ),
                fleetTypes: [],
            };
        } else if (field === "load_type_id") {
            const currentOrder = updatedOrders[index];
            updatedFilteredOptions[index] = {
                ...updatedFilteredOptions[index],
                fleetTypes: getFilteredOptions(
                    "fleet_type",
                    currentOrder.from_location_id,
                    currentOrder.to_location_id,
                    value
                ),
            };
        }

        setFilteredOptions(updatedFilteredOptions);

        // Update the form fields
        multiTripJobOrdersForm.setFieldsValue({
            single_trip_job_orders: updatedOrders,
        });

        console.log("updated orders: ", updatedOrders);
    };

    // Add Single Trip Job Order
    const addSingleTripJobOrder = () => {
        const currentOrders =
            multiTripJobOrdersForm.getFieldValue("single_trip_job_orders") || [];
        const newOrder = {
            id: null,
            demurrage_date: null,
            delivery_date: null,
            priority: "",
            from_location_id: null,
            to_location_id: null,
            load_type_id: null,
            fleet_type_id: null,
            unit_price: null,
            tax: null,
            total_price: null,
            transportation_id: null,
            transportation_mode: "Internal",
            description: "",
        };
        const updatedOrders = [...currentOrders, newOrder];
        multiTripJobOrdersForm.setFieldsValue({
            single_trip_job_orders: updatedOrders,
        });

        // Initialize filtered options for the new order
        const newIndex = updatedOrders.length - 1;
        setActivePanels([...activePanels, newIndex.toString()]);
        setFilteredOptions((prev) => ({
            ...prev,
            [newIndex]: {
                fromLocations: getUniqueOptions("from_location"),
                toLocations: [],
                loadTypes: [],
                fleetTypes: [],
            },
        }));
    };

    // Delete Single Trip Job Order
    const deleteSingleTripJobOrder = (index) => {
        const currentOrders =
            multiTripJobOrdersForm.getFieldValue("single_trip_job_orders") || [];
        const updatedOrders = currentOrders.filter((_, i) => i !== index);
        multiTripJobOrdersForm.setFieldsValue({
            single_trip_job_orders: updatedOrders,
        });

        // Remove corresponding filtered options
        const updatedFilteredOptions = { ...filteredOptions };
        delete updatedFilteredOptions[index];
        // Re-index the remaining options
        const reindexedOptions = {};
        Object.keys(updatedFilteredOptions).forEach((key) => {
            const numericKey = parseInt(key, 10);
            const newKey = numericKey > index ? numericKey - 1 : numericKey;
            reindexedOptions[newKey] = updatedFilteredOptions[key];
        });
        setFilteredOptions(reindexedOptions);
    };

    // Define Tabs (Single Trip Job Orders)
    const tabItems = [
        {
            label: (
                <>
                    <FileFilled /> Documents{" "}
                </>
            ),
            key: "2",
            children:
                operationMode === "Edit" ? (
                    <DocumentsGeneric
                        entityType={"MultiTripJobOrder"}
                        entityId={selectedMultiTripJobOrder?.id}
                        isExpiryDate={false}
                    />
                ) : (
                    <Documents
                        documents={documents}
                        setDocuments={setDocuments}
                        documentsData={documentsData}
                        mode={operationMode}
                    />
                ),
        },
        {
            label: "👋 Mate",
            key: "3",
            children: (
                <Mate
                    entityType="MultiTripJobOrder"
                    entityId={selectedMultiTripJobOrder?.id}
                />
            ),
        },
    ];

    // Render
    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Multi Trip"
                    ghost={false}
                    extra={[
                        <Button
                            type="primary"
                            onClick={() => {
                                showModal("Add");
                            }}
                            key="add-job-order"
                        >
                            + Add Job Order
                        </Button>,
                    ]}
                />
            </Card>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: "50%" }}
                />
                <Table
                    columns={[
                        {
                            title: "#",
                            key: "index",
                            width: "5%",
                            render: (text, record, index) => {
                                return (
                                    (pagination.current - 1) * pagination.pageSize + index + 1
                                );
                            },
                        },
                        {
                            title: "Reference No.",
                            dataIndex: "reference_number",
                        },
                        {
                            title: "Customer Name",
                            dataIndex: ["customer", "company_name"],
                            key: "customerName",
                            render: (text) => text || "-",
                        },
                        {
                            title: "Trips",
                            key: "singleTripsCount",
                            width: "10%",
                            render: (record) => (
                                <Tag
                                    color={record.single_trip_job_orders?.length ? "blue" : "red"}
                                >
                                    {record.single_trip_job_orders?.length || 0}
                                </Tag>
                            ),
                        },
                        {
                            title: "Contract",
                            dataIndex: ["customer_contract", "contract_name"],
                            key: "contract",
                            render: (text) => text || "-",
                        },
                        {
                            title: "Status",
                            key: "statusColor",
                            width: "10%",
                            render: (record) => (
                                <Tag color={getMultiTripStatusColor(record.status)}>{record.status}</Tag>
                            ),
                        },
                        {
                            title: "Action",
                            align: "center",
                            width: "20%",
                            render: (record) => (
                                <Space size="middle">
                                    <Button
                                        onClick={() => showModal("View", record)}
                                        style={{
                                            padding: 6,
                                            fontSize: "18px",
                                            borderRadius: "5px",
                                            backgroundColor: "rgba(82, 196, 26, 0.2)",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <EyeFilled style={{ color: "#52c41a" }} />
                                    </Button>
                                    <Button
                                        onClick={() => showModal("Edit", record)}
                                        style={{
                                            padding: 6,
                                            fontSize: "18px",
                                            borderRadius: "5px",
                                            backgroundColor: "rgba(24, 144, 255, 0.2)",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <EditTwoTone twoToneColor="#1890ff" />
                                    </Button>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            const baseUrl = window.location.origin;
                                            const path = `/dispatch/orders?id=${record.id}`;
                                            window.open(baseUrl + path, "_blank");
                                        }}
                                        title="Go to Dispatch Order"
                                        style={{
                                            padding: 6,
                                            fontSize: "18px",
                                            borderRadius: "5px",
                                            backgroundColor: "#f6ffed",
                                            border: "1px solid #b7eb8f",
                                            color: "#52c41a",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <LinkOutlined />
                                    </Button>
                                    <Popconfirm
                                        title="Are you sure to delete this job order?"
                                        onConfirm={() => handleDelete(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteTwoTone
                                            style={{
                                                padding: 6,
                                                fontSize: "18px",
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            twoToneColor="#ff0000"
                                            title="Delete Entity"
                                        />
                                    </Popconfirm>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={multiTripJobOrdersAllData}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={pagination}
                    onChange={handleContractsTableChange}
                    bordered
                />
            </Card>
            <Drawer
                title={`${operationMode} Job Order`}
                placement="right"
                onClose={handleCancel}
                visible={drawerVisible}
                width={"80%"}
                extra={
                    <Space>
                        {operationMode === "View" && (
                            <Button
                                style={{ marginRight: "20px" }}
                                type="primary"
                                loading={loading}
                                onClick={() => {
                                    showModal("Edit", selectedMultiTripJobOrder);
                                }}
                            >
                                Edit
                            </Button>
                        )}
                    </Space>
                }
            >
                <Form
                    form={multiTripJobOrdersForm}
                    layout="vertical"
                    onFinish={handleAddEdit}
                >
                    <Row justify="space-between" align="middle" style={{ marginBottom: "20px" }}>
                        <Col>
                            {selectedMultiTripJobOrder?.reference_number && (
                                <span style={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    color: '#1890ff',
                                    background: 'rgba(24, 144, 255, 0.1)',
                                    padding: '6px 12px',
                                    borderRadius: '6px',
                                    display: 'inline-flex',
                                    alignItems: 'center'
                                }}>
                                    <span style={{ color: '#595959', marginRight: '8px', fontWeight: 'normal' }}>
                                        Reference No.:
                                    </span>
                                    {selectedMultiTripJobOrder.reference_number}
                                </span>
                            )}
                        </Col>
                        <Col>
                            <Space>
                                {operationMode === "Edit" && selectedMultiTripJobOrder && (
                                    <>
                                        {selectedMultiTripJobOrder.status ===
                                            MultiTripJobOrderStatus.READY_FOR_INVOICE && (
                                                <Button
                                                    type="primary"
                                                    loading={loading}
                                                    onClick={() => {
                                                        changeStatus(
                                                            selectedMultiTripJobOrder.id,
                                                            MultiTripJobOrderStatus.DELIVERED
                                                        );
                                                    }}
                                                >
                                                    Generate Invoice
                                                </Button>
                                            )}
                                    </>
                                )}
                                {operationMode !== "View" && (
                                    <Button type="primary" loading={loading} htmlType="submit">
                                        {operationMode === "Add" ? "Save" : "Update"}
                                    </Button>
                                )}
                                {selectedMultiTripJobOrder && (
                                    <Tag color={getMultiTripStatusColor(selectedMultiTripJobOrder.status)}>
                                        {selectedMultiTripJobOrder.status}
                                    </Tag>
                                )}
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="customer_id"
                                label="Customer"
                                rules={[{ required: true, message: "Customer is required" }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={multiTripJobOrdersRawData.customers?.map(
                                        (customer) => ({
                                            label: customer.company_name,
                                            value: customer.id,
                                        })
                                    )}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    disabled={operationMode === "View"}
                                    onChange={onCustomerChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="customer_contract_id"
                                label="Contract Name"
                                rules={[{ required: true, message: "Contract is required" }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={contracts?.map((contract) => ({
                                        label: contract.contract_name,
                                        value: contract.id,
                                    }))}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    disabled={operationMode === "View"}
                                    onChange={onContractChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="client_reference"
                                label="Client Reference"
                                hasFeedback
                            >
                                <Input disabled={operationMode === "View"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item name="bl_number" label="BL Number" hasFeedback>
                                <Input disabled={operationMode === "View"} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Single Trip Job Orders */}
                    <Collapse
                        activeKey={activePanels}
                        onChange={(keys) => setActivePanels(keys)}
                        style={{ marginTop: "16px" }}
                    >
                        {multiTripJobOrdersForm
                            .getFieldValue("single_trip_job_orders")
                            ?.map((order, index) => (
                                <Panel
                                    header={`Trip ${index + 1}`}
                                    key={index}
                                    extra={
                                        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                                            <Col flex="auto" style={{ marginRight: '16px' }}>
                                                {order?.reference_number && (
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: '#1890ff',
                                                        background: 'rgba(24, 144, 255, 0.1)',
                                                        padding: '4px 8px',
                                                        borderRadius: '4px',
                                                        display: 'inline-flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <span style={{ color: '#595959', marginRight: '4px', fontWeight: 'normal' }}>
                                                            Reference No.:
                                                        </span>
                                                        {order.reference_number}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col>
                                                <Space>
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            const baseUrl = window.location.origin;
                                                            const path = `/dispatch/orders?id=${order?.id}`;
                                                            window.open(baseUrl + path, "_blank");
                                                        }}
                                                        title="Go to Dispatch Order"
                                                        style={{
                                                            padding: "4px 12px",
                                                            fontSize: "14px",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f6ffed",
                                                            border: "1px solid #b7eb8f",
                                                            color: "#52c41a",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "6px",
                                                        }}
                                                    >
                                                        <LinkOutlined />
                                                        <span>View Dispatch Order</span>
                                                    </Button>
                                                    {operationMode === "Edit" &&
                                                        order.status === JobOrderStatus.DRAFT && (
                                                            <Button
                                                                style={{ marginRight: "20px" }}
                                                                type="primary"
                                                                loading={loading}
                                                                onClick={() => {
                                                                    changeSingleTripStatus(
                                                                        order.id,
                                                                        JobOrderStatus.WITH_DISPATCHER
                                                                    );
                                                                }}
                                                            >
                                                                Move to Dispatch
                                                            </Button>
                                                        )}
                                                    {operationMode === "Edit" &&
                                                        order.status ===
                                                        JobOrderStatus.WAITING_FOR_DRIVER_ACCEPTANCE && (
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: "20px" }}
                                                                    type="primary"
                                                                    loading={loading}
                                                                    onClick={() => {
                                                                        NotificationWithIcon(
                                                                            "info",
                                                                            "WayBill",
                                                                            `WayBill will be generated`
                                                                        );
                                                                    }}
                                                                >
                                                                    Generate WayBill
                                                                </Button>
                                                                <Button
                                                                    style={{ marginRight: "20px" }}
                                                                    type="primary"
                                                                    loading={loading}
                                                                    onClick={() => {
                                                                        changeSingleTripStatus(
                                                                            order.id,
                                                                            JobOrderStatus.IN_TRANSIT
                                                                        );
                                                                    }}
                                                                >
                                                                    Start Trip
                                                                </Button>
                                                            </>
                                                        )}
                                                    {operationMode === "Edit" &&
                                                        order.status === JobOrderStatus.IN_TRANSIT && (
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: "20px" }}
                                                                    type="primary"
                                                                    loading={loading}
                                                                    onClick={() => {
                                                                        NotificationWithIcon(
                                                                            "info",
                                                                            "POD",
                                                                            `POD will be provided`
                                                                        );
                                                                    }}
                                                                >
                                                                    Request POD
                                                                </Button>
                                                                <Button
                                                                    style={{ marginRight: "20px" }}
                                                                    type="primary"
                                                                    loading={loading}
                                                                    onClick={() => {
                                                                        changeSingleTripStatus(
                                                                            order.id,
                                                                            JobOrderStatus.DELIVERED
                                                                        );
                                                                    }}
                                                                >
                                                                    Deliver
                                                                </Button>
                                                            </>
                                                        )}
                                                    <Tag
                                                        bordered={false}
                                                        color={getStatusColor(order?.status ?? "Draft")}
                                                    >
                                                        {order?.status ?? "Draft"}
                                                    </Tag>
                                                    {operationMode !== "View" && (
                                                        <DeleteTwoTone
                                                            twoToneColor="#eb2f96"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteSingleTripJobOrder(index);
                                                            }}
                                                        />
                                                    )}
                                                </Space>
                                            </Col>
                                        </Row>

                                    }
                                >
                                    <Form.Item
                                        name={["single_trip_job_orders", index, "id"]}
                                        hidden={true}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "demurrage_date",
                                                ]}
                                                label="Demurrage Date"
                                            >
                                                <DatePicker
                                                    format="YYYY-MM-DD"
                                                    disabled={operationMode === "View"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "delivery_date",
                                                ]}
                                                label="Delivery Date"
                                            >
                                                <DatePicker
                                                    format="YYYY-MM-DD"
                                                    disabled={operationMode === "View"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "detention_date",
                                                ]}
                                                label="Detention Date"
                                            >
                                                <DatePicker
                                                    format="YYYY-MM-DD"
                                                    disabled={operationMode === "View"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={["single_trip_job_orders", index, "priority"]}
                                                label="Priority"
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    options={[
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <LowPriorityIcon
                                                                        width="24px"
                                                                        height="24px"
                                                                        style={{ marginRight: 8 }}
                                                                    />
                                                                    <span>Low</span>
                                                                </div>
                                                            ),
                                                            value: "Low",
                                                        },
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <MediumPriorityIcon
                                                                        width="24px"
                                                                        height="24px"
                                                                        style={{ marginRight: 8 }}
                                                                    />
                                                                    <span>Medium</span>
                                                                </div>
                                                            ),
                                                            value: "Medium",
                                                        },
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <HighPriorityIcon
                                                                        width="24px"
                                                                        height="24px"
                                                                        style={{ marginRight: 8 }}
                                                                    />
                                                                    <span>High</span>
                                                                </div>
                                                            ),
                                                            value: "High",
                                                        },
                                                    ]}
                                                    disabled={operationMode === "View"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "from_location_id",
                                                ]}
                                                label="From Location"
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    options={filteredOptions[index]?.fromLocations?.map(
                                                        (loc) => ({
                                                            label: loc.location,
                                                            value: loc.id,
                                                        })
                                                    )}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    disabled={operationMode === "View"}
                                                    onChange={(value) =>
                                                        handleSingleTripFieldChange(
                                                            index,
                                                            "from_location_id",
                                                            value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "to_location_id",
                                                ]}
                                                label="To Location"
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    options={filteredOptions[index]?.toLocations?.map(
                                                        (loc) => ({
                                                            label: loc.location,
                                                            value: loc.id,
                                                        })
                                                    )}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    disabled={
                                                        !multiTripJobOrdersForm.getFieldValue([
                                                            "single_trip_job_orders",
                                                            index,
                                                            "from_location_id",
                                                        ]) || operationMode === "View"
                                                    }
                                                    onChange={(value) =>
                                                        handleSingleTripFieldChange(
                                                            index,
                                                            "to_location_id",
                                                            value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={["single_trip_job_orders", index, "load_type_id"]}
                                                label="Load Type"
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    options={filteredOptions[index]?.loadTypes?.map(
                                                        (load) => ({
                                                            label: load.type,
                                                            value: load.id,
                                                        })
                                                    )}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    disabled={
                                                        !multiTripJobOrdersForm.getFieldValue([
                                                            "single_trip_job_orders",
                                                            index,
                                                            "to_location_id",
                                                        ]) || operationMode === "View"
                                                    }
                                                    onChange={(value) =>
                                                        handleSingleTripFieldChange(
                                                            index,
                                                            "load_type_id",
                                                            value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "fleet_type_id",
                                                ]}
                                                label="Fleet Type"
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    options={filteredOptions[index]?.fleetTypes?.map(
                                                        (fleet) => ({
                                                            label: fleet.name,
                                                            value: fleet.id,
                                                        })
                                                    )}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    disabled={
                                                        !multiTripJobOrdersForm.getFieldValue([
                                                            "single_trip_job_orders",
                                                            index,
                                                            "load_type_id",
                                                        ]) || operationMode === "View"
                                                    }
                                                    onChange={(value) =>
                                                        handleSingleTripFieldChange(
                                                            index,
                                                            "fleet_type_id",
                                                            value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={["single_trip_job_orders", index, "unit_price"]}
                                                label="Unit Price"
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={["single_trip_job_orders", index, "tax"]}
                                                label="Tax"
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={["single_trip_job_orders", index, "total_price"]}
                                                label="Total Price"
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name={[
                                                    "single_trip_job_orders",
                                                    index,
                                                    "transportation_mode",
                                                ]}
                                                label="Transportation Mode"
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    options={[
                                                        { label: "Internal", value: "Internal" },
                                                        { label: "External", value: "External" },
                                                    ]}
                                                    disabled={operationMode === "View"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={16}>
                                            <Form.Item
                                                name={["single_trip_job_orders", index, "description"]}
                                                label="Description"
                                            >
                                                <Input.TextArea
                                                    rows={2}
                                                    disabled={operationMode === "View"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Form.Item
                                            name={[
                                                "single_trip_job_orders",
                                                index,
                                                "transportation_id",
                                            ]}
                                            hidden={true}
                                        >
                                            <Input type="hidden" />
                                        </Form.Item>
                                    </Row>
                                </Panel>
                            ))}
                    </Collapse>
                    {operationMode !== "View" && (
                        <Button
                            type="dashed"
                            onClick={addSingleTripJobOrder}
                            style={{ width: "100%", marginTop: "16px" }}
                        >
                            + Add Single Trip Job Order
                        </Button>
                    )}

                    <Tabs
                        activeKey={activeTabKey}
                        onChange={setActiveTabKey}
                        items={tabItems}
                        style={{ marginTop: "24px" }}
                    />
                </Form>
            </Drawer>
        </>
    );
};

export default MultiTripJobOrder;
