import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    Form,
    Button,
    Table,
    Drawer,
    Row,
    Space,
    Tabs,
    Popconfirm,
    Input,
    Tag,
    Col,
    Select,
    DatePicker,
    Checkbox,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
    addSingleTripJobOrder,
    changeJobOrderStatus,
    deleteSingleTripJobOrder,
    getCustomerContractsByCustomerId,
    getSingleTripJobOrder,
    getSingleTripJobOrders,
    getTransportationsByContractId,
    rawDataJobOrder,
    updateSingleTripJobOrder,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
    DeleteTwoTone,
    EditTwoTone,
    EyeFilled,
    FileFilled,
    InfoCircleFilled,
    LinkOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { debounce } from "lodash";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Documents from "../Documents";
import { default as DocumentsGeneric } from "../../../../components/Documents";
import JobOrderStatus from "../../../../constants/JobOrderStatus";
import HighPriorityIcon from "../../../../icons/HighPriorityIcon";
import LowPriorityIcon from "../../../../icons/LowPriorityIcon";
import MediumPriorityIcon from "../../../../icons/MediumPriorityIcon";
import Mate from "../../../../components/Mate/Mate";
import JobOrderType from "../../../../constants/JobOrderType";
dayjs.extend(utc);

const SingleTripJobOrder = () => {
    const { jobOrderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [jobOrdersAllData, setJobOrdersAllData] = useState([]);
    const [transportations, setTransportations] = useState([]);
    const [jobOrdersRawData, setJobOrdersRawData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTabKey, setActiveTabKey] = useState("1");
    const [selectedJobOrder, setSelectedJobOrder] = useState(null);
    const [operationMode, setOperationMode] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [hasContract, setHasContract] = useState(true);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedTransportation, setSelectedTransportation] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const [documents, setDocuments] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    const [jobOrdersForm] = Form.useForm();

    const [fromLocations, setFromLocations] = useState([]);
    const [toLocations, setToLocations] = useState([]);
    const [loadTypes, setLoadTypes] = useState([]);
    const [fleetTypes, setFleetTypes] = useState([]);
    const [selectedFromLocation, setSelectedFromLocation] = useState(null);
    const [selectedToLocation, setSelectedToLocation] = useState(null);
    const [selectedLoadType, setSelectedLoadType] = useState(null);
    const [selectedFleetType, setSelectedFleetType] = useState(null);
    const [unitPrice, setUnitPrice] = useState(null);
    const [tax, setTax] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!selectedContract) {
            setFromLocations(jobOrdersRawData.from_locations);
            setToLocations(jobOrdersRawData.to_locations);
            setLoadTypes(jobOrdersRawData.load_types);
            setFleetTypes(jobOrdersRawData.fleet_types);
        } else {
            if (operationMode === "Add") {
                setFromLocations(getUniqueOptions("from_location"));
            }
        }
    }, [selectedContract, jobOrdersRawData]);

    const onSelectedFromLocationChange = (value) => {
        if (value && selectedContract) {
            setToLocations(getFilteredOptions("to_location", value));
            resetFormFields([
                ["transportations", 0, "to_location_id"],
                ["transportations", 0, "load_type_id"],
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
        } else if (!value && selectedContract) {
            setSelectedToLocation(null);
            setSelectedLoadType(null);
            setSelectedFleetType(null);
            resetFormFields([
                ["transportations", 0, "to_location_id"],
                ["transportations", 0, "load_type_id"],
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
            clearFields();
        }
    };

    const onSelectedToLocationChange = (value) => {
        if (value && selectedContract) {
            setLoadTypes(
                getFilteredOptions("load_type", selectedFromLocation, value)
            );
            resetFormFields([
                ["transportations", 0, "load_type_id"],
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
        } else if (!value && selectedContract) {
            setSelectedLoadType(null);
            setSelectedFleetType(null);
            resetFormFields([
                ["transportations", 0, "load_type_id"],
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
            clearFields();
        }
    };

    const onSelectedLoadTypeChange = (value) => {
        if (value && selectedContract) {
            setFleetTypes(
                getFilteredOptions(
                    "fleet_type",
                    selectedFromLocation,
                    selectedToLocation,
                    value
                )
            );
            resetFormFields([
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
        } else if (!value && selectedContract) {
            setSelectedFleetType(null);
            resetFormFields([
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
            clearFields();
        }
    };

    useEffect(() => {
        if (selectedFleetType && selectedContract) {
            const selectedTransportationTemp = transportations.find(
                (t) =>
                    t.from_location.id === parseInt(selectedFromLocation) &&
                    t.to_location.id === parseInt(selectedToLocation) &&
                    t.load_type.id === parseInt(selectedLoadType) &&
                    t.fleet_type.id === parseInt(selectedFleetType)
            );
            setSelectedTransportation(selectedTransportationTemp);
            if (selectedTransportationTemp) {
                const calculatedUnitPrice = parseFloat(
                    selectedTransportationTemp.charges
                ).toFixed(2);
                const calculatedTax = parseFloat(calculatedUnitPrice * 0.15).toFixed(2);
                const calculatedTotalPrice = (
                    parseFloat(calculatedUnitPrice) + parseFloat(calculatedTax)
                ).toFixed(2);
                setUnitPrice(calculatedUnitPrice);
                setTax(calculatedTax);
                setTotalPrice(calculatedTotalPrice);
            }
        } else if (!selectedFleetType && selectedContract) {
            clearFields();
        }
    }, [selectedFleetType, selectedContract]);

    useEffect(() => {
        if (unitPrice !== null && tax !== null && totalPrice !== null) {
            jobOrdersForm.setFieldsValue({
                transportations: [
                    {
                        ...jobOrdersForm.getFieldValue("transportations")[0],
                        unit_price: unitPrice,
                        tax: tax,
                        total_price: totalPrice,
                    },
                ],
            });
        }
    }, [unitPrice, tax, totalPrice, jobOrdersForm]);

    useEffect(() => {
        if (!selectedContract) {
            if (unitPrice === null || unitPrice === "" || unitPrice === undefined) {
                setTax("");
                setTotalPrice("");
            } else {
                const calculatedTax = parseFloat(unitPrice * 0.15).toFixed(2);
                const calculatedTotalPrice = (
                    parseFloat(unitPrice) + parseFloat(calculatedTax)
                ).toFixed(2);
                setTax(calculatedTax);
                setTotalPrice(calculatedTotalPrice);
            }
        }
    }, [unitPrice]);

    const resetFormFields = (fields) => {
        const currentValues = jobOrdersForm.getFieldsValue();
        const resetValues = fields.reduce((acc, [section, index, field]) => {
            if (!acc[section]) acc[section] = [...currentValues[section]];
            acc[section][index] = { ...acc[section][index], [field]: null };
            return acc;
        }, {});

        jobOrdersForm.setFieldsValue(resetValues);
    };

    const clearFields = () => {
        setUnitPrice(null);
        setTax(null);
        setTotalPrice(null);
        jobOrdersForm.setFieldsValue({
            transportations: [
                {
                    ...jobOrdersForm.getFieldValue("transportations")[0],
                    unit_price: unitPrice,
                    tax: tax,
                    total_price: totalPrice,
                },
            ],
        });
    };

    const getUniqueOptions = (field) => {
        return Array.from(new Set(transportations.map((t) => t[field].id))).map(
            (id) => transportations.find((t) => t[field].id === id)[field]
        );
    };

    const getFilteredOptions = (
        field,
        fromLocation,
        toLocation = null,
        loadType = null
    ) => {
        return Array.from(
            new Set(
                transportations
                    .filter(
                        (t) =>
                            t.from_location.id === parseInt(fromLocation) &&
                            (!toLocation || t.to_location.id === parseInt(toLocation)) &&
                            (!loadType || t.load_type.id === parseInt(loadType))
                    )
                    .map((t) => t[field].id)
            )
        ).map((id) => transportations.find((t) => t[field].id === id)[field]);
    };

    const fetchData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            if (jobOrdersRawData.length === 0) {
                const rawDataResponse = await rawDataJobOrder();
                setJobOrdersRawData(rawDataResponse.data.data);
            }
            const jobOrdersResponse = await getSingleTripJobOrders({
                job_order_type: JobOrderType.SINGLE_TRIP,
                page: currentPage,
                search: searchTerm,
            });

            const jobOrdersData = jobOrdersResponse.data.data.job_orders;
            setJobOrdersAllData(jobOrdersData);

            const jobOrderId = searchParams.get('id');
            if (jobOrderId) {
                // Check if the single trip order is in the current page
                let jobOrderFromParam = jobOrdersData.find(
                    order => order.id === parseInt(jobOrderId)
                );

                if (!jobOrderFromParam) {
                    // If not found, fetch it separately
                    const singleOrderResponse = await getSingleTripJobOrder(jobOrderId);
                    jobOrderFromParam = singleOrderResponse.data.data;
                }

                if (jobOrderFromParam) {
                    showJobOrderDetailsDrawer(jobOrderFromParam);
                }
            }

            const paginationData = jobOrdersResponse.data.data.pagination;
            setPagination({
                current: paginationData.current_page,
                pageSize: paginationData.per_page,
                total: paginationData.total,
                showSizeChanger: false,
            });
        } catch (error) {
            console.error("Fetching data failed:", error);
        } finally {
            setIsDataLoading(false);
        }
    }, [currentPage, searchTerm, jobOrdersRawData.length, searchParams]);

    const onHasContractChange = (value) => {
        if (!value) {
            setSelectedTransportation(null);
            setSelectedContract(null);
            setSelectedFromLocation(null);
            setSelectedToLocation(null);
            setSelectedLoadType(null);
            setSelectedFleetType(null);
            setUnitPrice(null);
            setTax(null);
            setTotalPrice(null);
            jobOrdersForm.resetFields(["customer_contract_id"]);
            resetFormFields([
                ["transportations", 0, "from_location_id"],
                ["transportations", 0, "to_location_id"],
                ["transportations", 0, "load_type_id"],
                ["transportations", 0, "fleet_type_id"],
                ["transportations", 0, "unit_price"],
                ["transportations", 0, "tax"],
                ["transportations", 0, "total_price"],
            ]);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, fetchData]);

    useEffect(() => {
        if (jobOrderId) {
            const jobOrderToShow = jobOrdersAllData.find(
                (jobOrder) => jobOrder.id === parseInt(jobOrderId)
            );
            if (jobOrderToShow) {
                showJobOrderDetailsDrawer(jobOrderToShow);
            }
        }
    }, [jobOrderId, jobOrdersAllData]);

    const debouncedFetchData = useCallback(
        debounce(() => {
            fetchData();
        }, 500),
        [fetchData]
    );

    useEffect(() => {
        debouncedFetchData();
        return () => debouncedFetchData.cancel();
    }, [searchTerm, debouncedFetchData]);

    const handleCancel = () => {
        setSelectedTransportation(null);
        setHasContract(false);
        setSelectedContract(null);
        setSelectedFromLocation(null);
        setSelectedToLocation(null);
        setSelectedLoadType(null);
        setSelectedFleetType(null);
        setUnitPrice(null);
        setTax(null);
        setTotalPrice(null);
        setDrawerVisible(false);
        setActiveTabKey("1");
        jobOrdersForm.resetFields();
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('id');
        window.history.replaceState({}, '', `${window.location.pathname}${newSearchParams.toString() ? '?' + newSearchParams.toString() : ''}`);
    };

    const handleAPIResponse = (response, isAdding) => {
        if (response.success === true) {
            NotificationWithIcon(
                "success",
                "Job Order",
                `Job Order ${isAdding ? "Added" : "Updated"} Successfully`
            );
            fetchData();
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleAddEdit = async () => {
        try {
            setLoading(true);
            const values = await jobOrdersForm.validateFields();
            const transportationsFormData = values.transportations;
            delete values.transportations;
            const jobOrderObj = {
                ...values,
                id: selectedJobOrder?.id,
                has_contract: values.has_contract ? 1 : 0,
                job_order_type: "Single Trip",
                demurrage_date: values.demurrage_date
                    ? dayjs.utc(values.demurrage_date).local().format("YYYY-MM-DD")
                    : "",
                expiry_date: values.expiry_date
                    ? dayjs.utc(values.expiry_date).local().format("YYYY-MM-DD")
                    : "",
                detention_date: values.detention_date
                    ? dayjs.utc(values.detention_date).local().format("YYYY-MM-DD")
                    : "",
                delivery_date: values.delivery_date
                    ? dayjs.utc(values.delivery_date).local().format("YYYY-MM-DD")
                    : "",
            };

            const formData = new FormData();
            Object.keys(jobOrderObj).forEach((key) => {
                if (jobOrderObj[key] !== undefined) {
                    formData.append(key, jobOrderObj[key]);
                }
            });
            documents.forEach((item, index) => {
                if (item.title && item.document) {
                    formData.append(`documents[${index}][title]`, item.title);
                    formData.append(
                        `documents[${index}][expiry_date]`,
                        item.expiry_date
                            ? dayjs.utc(item.expiry_date).local().format("YYYY-MM-DD")
                            : ""
                    );
                    formData.append(`documents[${index}][document]`, item.document.file);
                }
            });
            transportationsFormData.forEach((item, index) => {
                Object.keys(item).forEach((key) => {
                    if (item[key] !== undefined) {
                        if (key === "transportation_id") {
                            formData.append(
                                `transportations[${index}][${key}]`,
                                item[key] ?? ""
                            );
                        } else {
                            formData.append(`transportations[${index}][${key}]`, item[key]);
                        }
                    }
                });
                if (operationMode === "Add") {
                    formData.append(
                        `transportations[${index}][transportation_id]`,
                        selectedTransportation?.id ?? ""
                    );
                }
            });

            let response;
            if (operationMode === "Add") {
                response = await addSingleTripJobOrder(formData);
            } else {
                response = await updateSingleTripJobOrder(formData, selectedJobOrder?.id);
            }
            handleAPIResponse(response.data, operationMode === "Add");
            handleCancel();
        } catch (error) {
            console.error("Validation or API call failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = (id) => {
        deleteSingleTripJobOrder(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Job Order Deleted", data.message);
                    fetchData();
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const changeStatus = (id, status) => {
        changeJobOrderStatus(id, { status: status })
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon(
                        "success",
                        "Job Order sent to dispatch",
                        data.message
                    );
                    handleCancel();
                    fetchData();
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const showModal = (mode, jobOrder = null) => {
        setSelectedJobOrder(jobOrder);
        setOperationMode(mode);
        setDisabled(mode === "View");
        let initialValues;
        if (mode === "Add") {
            initialValues = {
                id: null,
                reference_number: null,
                customer_id: null,
                has_contract: true,
                customer_contract_id: "",
                demurrage_date: null,
                expiry_date: null,
                detention_date: null,
                delivery_date: null,
                priority: null,
                client_reference: "",
                bl_number: "",
                job_order_type: "Single Trip",
                transportations: [
                    {
                        from_location_id: null,
                        to_location_id: null,
                        unit_price: null,
                        tax: null,
                        total_price: null,
                        transportation_mode: "Internal",
                        load_type_id: null,
                        fleet_type_id: null,
                        description: "",
                        transportation_id: null,
                    },
                ],
            };
            setHasContract(true);
            setDocumentsData([]);
        } else {
            initialValues = {
                ...jobOrder,
                customer_id: jobOrder.customer?.id,
                customer_contract_id: jobOrder.customer_contract?.id,
                demurrage_date: jobOrder.demurrage_date
                    ? dayjs(jobOrder.demurrage_date, "YYYY-MM-DD")
                    : null,
                expiry_date: jobOrder.expiry_date
                    ? dayjs(jobOrder.expiry_date, "YYYY-MM-DD")
                    : null,
                detention_date: jobOrder.detention_date
                    ? dayjs(jobOrder.detention_date, "YYYY-MM-DD")
                    : null,
                delivery_date: jobOrder.delivery_date
                    ? dayjs(jobOrder.delivery_date, "YYYY-MM-DD")
                    : null,
                transportations: jobOrder.load_info.map((transportation) => ({
                    from_location_id: transportation.from_location.id,
                    to_location_id: transportation.to_location.id,
                    unit_price: transportation.unit_price,
                    tax: transportation.tax,
                    total_price: transportation.total_price,
                    transportation_mode: transportation.transportation_mode,
                    load_type_id: transportation.load_type?.id,
                    fleet_type_id: transportation.fleet_type?.id,
                    description: transportation.description,
                    transportation_id: transportation.transportation_id,
                })),
            };
            setHasContract(jobOrder.has_contract);

            setSelectedContract(jobOrder.customer_contract?.id);
            setSelectedFromLocation(jobOrder.load_info[0]?.from_location?.id);
            setSelectedToLocation(jobOrder.load_info[0]?.to_location?.id);
            setSelectedLoadType(jobOrder.load_info[0]?.load_type?.id);
            setSelectedFleetType(jobOrder.load_info[0]?.fleet_type?.id);
            setSelectedTransportation(jobOrder.load_info[0]?.transportation_id);
            if (jobOrder.has_contract) {
                setFromLocations([jobOrder.load_info[0]?.from_location]);
                setToLocations([jobOrder.load_info[0]?.to_location]);
                setLoadTypes([jobOrder.load_info[0]?.load_type]);
                setFleetTypes([jobOrder.load_info[0]?.fleet_type]);
                getCustomerContractsByCustomerId(jobOrder.customer?.id)
                    .then((response) => {
                        let data = response.data.data;
                        setContracts(data);
                    })
                    .catch((info) => {
                        NotificationWithIcon(
                            "error",
                            "Something Went Wrong",
                            info.response.data.message
                        );
                    });
            }
            setDocumentsData(jobOrder.documents);
        }
        setInitialValues(initialValues);
        jobOrdersForm.setFieldsValue(initialValues);
        setDrawerVisible(true);
    };

    const showJobOrderDetailsDrawer = (jobOrder) => {
        setOperationMode("View");
        setDisabled(true);
        setSelectedJobOrder(jobOrder);
        const initialValues = {
            ...jobOrder,
            customer_id: jobOrder.customer.id,
            customer_contract_id: jobOrder.customer_contract?.contract_name,
            demurrage_date: jobOrder.demurrage_date
                ? dayjs(jobOrder.demurrage_date, "YYYY-MM-DD")
                : null,
            expiry_date: jobOrder.expiry_date
                ? dayjs(jobOrder.expiry_date, "YYYY-MM-DD")
                : null,
            detention_date: jobOrder.detention_date
                ? dayjs(jobOrder.detention_date, "YYYY-MM-DD")
                : null,
            delivery_date: jobOrder.delivery_date
                ? dayjs(jobOrder.delivery_date, "YYYY-MM-DD")
                : null,
            transportations: jobOrder.load_info.map((transportation) => ({
                from_location_id: transportation.from_location.id,
                to_location_id: transportation.to_location.id,
                unit_price: transportation.unit_price,
                tax: transportation.tax,
                total_price: transportation.total_price,
                transportation_mode: transportation.transportation_mode,
                load_type_id: transportation.load_type.id,
                fleet_type_id: transportation.fleet_type.id,
                transportation_id: transportation.transportation_id,
                description: transportation.description,
            })),
        };
        onCustomerChange(jobOrder.customer?.id);
        setHasContract(jobOrder.has_contract);
        setDocumentsData(jobOrder.documents);
        setInitialValues(initialValues);
        jobOrdersForm.setFieldsValue(initialValues);
        setDrawerVisible(true);
    };

    const handleContractsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const onCustomerChange = (id) => {
        getCustomerContractsByCustomerId(id)
            .then((response) => {
                let data = response.data.data;
                setContracts(data);
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const onContractChange = (id) => {
        setSelectedFromLocation(null);
        setSelectedToLocation(null);
        setSelectedLoadType(null);
        setSelectedFleetType(null);
        resetFormFields([
            ["transportations", 0, "from_location_id"],
            ["transportations", 0, "to_location_id"],
            ["transportations", 0, "load_type_id"],
            ["transportations", 0, "fleet_type_id"],
            ["transportations", 0, "unit_price"],
            ["transportations", 0, "tax"],
            ["transportations", 0, "total_price"],
        ]);
        clearFields();
        getTransportationsByContractId(id)
            .then((response) => {
                let data = response.data.data;
                setTransportations(data);
                setSelectedContract(id);
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const getStatusColor = (statusName) => {
        switch (statusName) {
            case JobOrderStatus.DRAFT:
                return "gold";
            case JobOrderStatus.WITH_DISPATCHER:
                return "blue";
            case JobOrderStatus.WAITING_FOR_DRIVER_ACCEPTANCE:
                return "orange";
            case JobOrderStatus.WAYBILL_GENERATED:
                return "teal";
            case JobOrderStatus.IN_TRANSIT:
                return "green";
            case JobOrderStatus.POD_REQUESTED:
                return "purple";
            case JobOrderStatus.DELIVERED:
                return "lime";
            case JobOrderStatus.INVOICE_ISSUED:
                return "red";
            default:
                return "gray";
        }
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            },
        },
        {
            title: "Reference No.",
            dataIndex: "reference_number",
        },
        {
            title: "Customer Name",
            render: (record) => (
                <>{record.customer ? record.customer.company_name : "-"}</>
            ),
        },
        {
            title: "Contract",
            render: (record) => (
                <>
                    {record.customer_contract
                        ? record.customer_contract.contract_name
                        : "-"}
                </>
            ),
        },
        {
            title: "Detention Date",
            dataIndex: "detention_date",
        },
        {
            title: "Delivery Date",
            dataIndex: "delivery_date",
        },
        {
            title: "Priority",
            dataIndex: "priority",
            render: (priority) => (
                <span style={{ display: "flex", alignItems: "center" }}>
                    {priority === "Low" && <LowPriorityIcon width={'24px'} height={'24px'} style={{ marginRight: 8 }} />}
                    {priority === "Medium" && <MediumPriorityIcon width={'24px'} height={'24px'} style={{ marginRight: 8 }} />}
                    {priority === "High" && <HighPriorityIcon width={'24px'} height={'24px'} style={{ marginRight: 8 }} />}
                    {priority}
                </span>
            ),
        },
        {
            title: "Status",
            key: "statusColor",
            width: "5px",
            render: (record) => {
                return (
                    <Tag bordered={false} color={getStatusColor(record.status)}>
                        {record.status}
                    </Tag>
                );
            },
        },
        {
            title: "Action",
            align: "center",
            width: "200px",
            render: (record) => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            type="link"
                            onClick={() => {
                                showJobOrderDetailsDrawer(record);
                            }}
                            title="View Details"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <EyeFilled style={{ color: "#52c41a" }} />
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                const baseUrl = window.location.origin;
                                const path = `/dispatch/orders?id=${record.id}`;
                                window.open(baseUrl + path, "_blank");
                            }}
                            title="Go to Dispatch Order"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "#f6ffed",
                                border: "1px solid #b7eb8f",
                                color: "#52c41a",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <LinkOutlined />
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                showModal("Edit", record);
                            }}
                            title="Edit record"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(24, 144, 255, 0.2)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <EditTwoTone twoToneColor="#1890ff" />
                        </Button>
                        <Popconfirm
                            title="Are you sure to delete this entity?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const loadInfo = () => {
        return (
            <Row
                key="load_info"
                gutter={{ xs: 8, sm: 16, md: 20, lg: 24, xl: 24, xxl: 24 }}
                align="bottom"
            >
                {/* From City */}
                <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "from_location_id"]}
                        label="From"
                        hasFeedback
                        rules={[{ required: true, message: "Please select a from city" }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            options={fromLocations?.map((location) => ({
                                label: location.location,
                                value: location.id,
                            }))}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                                setSelectedFromLocation(value);
                                onSelectedFromLocationChange(value);
                            }}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                {/* To City */}
                <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "to_location_id"]}
                        label="To"
                        hasFeedback
                        rules={[{ required: true, message: "Please select a to city" }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            options={toLocations?.map((location) => ({
                                label: location.location,
                                value: location.id,
                            }))}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                                setSelectedToLocation(value);
                                onSelectedToLocationChange(value);
                            }}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                {/* Load Type */}
                <Col
                    span={3}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "load_type_id"]}
                        label="Load Type"
                        hasFeedback
                        rules={[{ required: true, message: "Please select a load type" }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            options={loadTypes?.map((load_type) => ({
                                label: load_type.type,
                                value: load_type.id,
                            }))}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                                setSelectedLoadType(value);
                                onSelectedLoadTypeChange(value);
                            }}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                {/* Fleet Type */}
                <Col
                    span={3}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "fleet_type_id"]}
                        label="Fleet Type"
                        hasFeedback
                        rules={[{ required: true, message: "Please select a fleet type" }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            options={fleetTypes?.map((fleet_type) => ({
                                label: fleet_type.name,
                                value: fleet_type.id,
                            }))}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => setSelectedFleetType(value)}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                {/* Unit Price */}
                <Col
                    span={3}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "unit_price"]}
                        label="Unit Price"
                        hasFeedback
                        rules={[{ required: true, message: "Please input the charges" }]}
                    >
                        <Input
                            type="number"
                            value={unitPrice}
                            onChange={(e) => setUnitPrice(e.target.value)}
                            disabled={selectedContract || disabled}
                        />
                    </Form.Item>
                </Col>
                {/* Tax */}
                <Col
                    span={3}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "tax"]}
                        label="Tax"
                        hasFeedback
                    >
                        <Input type="number" value={tax} disabled={true} />
                    </Form.Item>
                </Col>
                {/* Total Price */}
                <Col
                    span={3}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "total_price"]}
                        label="Total Price"
                        hasFeedback
                    >
                        <Input type="number" value={totalPrice} disabled={true} />
                    </Form.Item>
                </Col>
                {/* Transportation Mode */}
                <Col
                    span={5}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "transportation_mode"]}
                        label="Transportation Mode"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Please select a transportation mode",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            options={[
                                { label: "Internal", value: "Internal" },
                                { label: "External", value: "External" },
                            ]}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                <Col
                    span={3}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                >
                    <Form.Item
                        name={["transportations", 0, "description"]}
                        label="Description"
                        hasFeedback
                    >
                        <Input type="text" disabled={disabled} />
                    </Form.Item>
                </Col>
                <Form.Item
                    name={["transportations", 0, "transportation_id"]}
                    label="Transportation id"
                    hidden={true}
                    hasFeedback
                >
                    <Input type="text" disabled={disabled} />
                </Form.Item>
            </Row>
        );
    };

    const tabItems = [
        {
            label: (
                <>
                    <InfoCircleFilled /> Load Info{" "}
                </>
            ),
            key: "1",
            children: loadInfo(),
        },
        {
            label: (
                <>
                    <FileFilled /> Documents{" "}
                </>
            ),
            key: "2",
            children:
                operationMode === "Edit" ? (
                    <DocumentsGeneric
                        entityType={"JobOrder"}
                        entityId={selectedJobOrder?.id}
                        isExpiryDate={false}
                    />
                ) : (
                    <Documents
                        documents={documents}
                        setDocuments={setDocuments}
                        documentsData={documentsData}
                        mode={operationMode}
                    />
                ),
        },
        {
            label: '👋 Mate',
            key: '3',
            children: <Mate entityType="JobOrder" entityId={selectedJobOrder?.id} />,
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Single Trip"
                    ghost={false}
                    extra={[
                        <Button
                            type="primary"
                            onClick={() => {
                                showModal("Add");
                            }}
                            key="add-job-order"
                        >
                            + Add Job Order
                        </Button>,
                    ]}
                />
            </Card>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: "50%" }}
                />
                <Table
                    columns={tableColumns}
                    dataSource={jobOrdersAllData}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={pagination}
                    onChange={handleContractsTableChange}
                    bordered
                />
            </Card>
            <Drawer
                title={`${operationMode} Job Order`}
                placement="right"
                onClose={handleCancel}
                open={drawerVisible}
                width={"calc(100% - 200px)"}
                extra={
                    <Space>
                        <Button
                            type="link"
                            onClick={() => {
                                const baseUrl = window.location.origin;
                                const path = `/dispatch/orders?id=${selectedJobOrder?.id}`;
                                window.open(baseUrl + path, "_blank");
                            }}
                            title="Go to Dispatch Order"
                            style={{
                                padding: "4px 12px",
                                fontSize: "14px",
                                borderRadius: "4px",
                                backgroundColor: "#f6ffed",
                                border: "1px solid #b7eb8f",
                                color: "#52c41a",
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                            }}
                        >
                            <LinkOutlined />
                            <span>View Dispatch Order</span>
                        </Button>
                        {operationMode === "View" && (
                            <Button
                                style={{ marginRight: "20px" }}
                                type="primary"
                                loading={loading}
                                onClick={() => {
                                    showModal("Edit", selectedJobOrder);
                                }}
                            >
                                Edit
                            </Button>
                        )}
                    </Space>
                }
            >
                <Form
                    name="job-order-form"
                    onFinish={handleAddEdit}
                    layout="vertical"
                    form={jobOrdersForm}
                    initialValues={initialValues}
                >
                    <Row justify="space-between" align="middle" style={{ marginBottom: "20px" }}>
                        <Col>
                            {selectedJobOrder?.reference_number && (
                                <span style={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    color: '#1890ff',
                                    background: 'rgba(24, 144, 255, 0.1)',
                                    padding: '6px 12px',
                                    borderRadius: '6px',
                                    display: 'inline-flex',
                                    alignItems: 'center'
                                }}>
                                    <span style={{ color: '#595959', marginRight: '8px', fontWeight: 'normal' }}>
                                        Reference No.:
                                    </span>
                                    {selectedJobOrder.reference_number}
                                </span>
                            )}
                        </Col>
                        <Col>
                            <Space>
                                {operationMode === "Edit" &&
                                    selectedJobOrder.status === JobOrderStatus.DRAFT && (
                                        <Button
                                            style={{ marginRight: "20px" }}
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                changeStatus(selectedJobOrder.id, JobOrderStatus.WITH_DISPATCHER);
                                            }}
                                        >
                                            Move to Dispatch
                                        </Button>
                                    )}
                                {operationMode === "Edit" &&
                                    selectedJobOrder.status === JobOrderStatus.WAITING_FOR_DRIVER_ACCEPTANCE && (
                                        <Button
                                            style={{ marginRight: "20px" }}
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                NotificationWithIcon(
                                                    "info",
                                                    "WayBill",
                                                    `WayBill will be generated`
                                                );
                                            }}
                                        >
                                            Generate WayBill
                                        </Button>
                                    )}
                                {operationMode === "Edit" &&
                                    selectedJobOrder.status === JobOrderStatus.WAITING_FOR_DRIVER_ACCEPTANCE && (
                                        <Button
                                            style={{ marginRight: "20px" }}
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                changeStatus(selectedJobOrder.id, JobOrderStatus.IN_TRANSIT);
                                            }}
                                        >
                                            Start Trip
                                        </Button>
                                    )}
                                {operationMode === "Edit" &&
                                    selectedJobOrder.status === JobOrderStatus.IN_TRANSIT && (
                                        <Button
                                            style={{ marginRight: "20px" }}
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                NotificationWithIcon(
                                                    "info",
                                                    "POD",
                                                    `POD will be provided`
                                                );
                                            }}
                                        >
                                            Request POD
                                        </Button>
                                    )}
                                {operationMode === "Edit" &&
                                    selectedJobOrder.status === JobOrderStatus.IN_TRANSIT && (
                                        <Button
                                            style={{ marginRight: "20px" }}
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                changeStatus(selectedJobOrder.id, JobOrderStatus.DELIVERED);
                                            }}
                                        >
                                            Deliver
                                        </Button>
                                    )}
                                {operationMode !== "View" && (
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        onClick={handleAddEdit}
                                    >
                                        {selectedJobOrder ? "Update" : "Save"}
                                    </Button>
                                )}
                                <span>
                                    Status:{" "}
                                    <Tag bordered={false} color={getStatusColor(selectedJobOrder?.status ?? "Draft")}>
                                        {selectedJobOrder?.status ?? "Draft"}
                                    </Tag>
                                </span>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20, xl: 20, xxl: 20 }}>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="customer_id"
                                label="Customer"
                                rules={[{ required: true, message: "Customer is required" }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={jobOrdersRawData.customers?.map((customer) => ({
                                        label: customer.company_name,
                                        value: customer.id,
                                    }))}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    disabled={disabled}
                                    onChange={onCustomerChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="has_contract"
                                label=" "
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please check if customer has a contract",
                                    },
                                ]}
                            >
                                <Checkbox
                                    value="1"
                                    checked={hasContract}
                                    onChange={(e) => {
                                        setHasContract(e.target.checked);
                                        onHasContractChange(e.target.checked);
                                    }}
                                    disabled={disabled}
                                >
                                    Has Contract
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={6}
                            xxl={6}
                            className={!hasContract ? "hidden mb-3" : "mb-3"}
                        >
                            <Form.Item
                                name="customer_contract_id"
                                label="Contract Name"
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={contracts?.map((contract) => ({
                                        label: contract.contract_name,
                                        value: contract.id,
                                    }))}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    disabled={disabled}
                                    onChange={onContractChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="demurrage_date"
                                label="Demurrage Date"
                                hasFeedback
                            >
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    allowClear
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item name="expiry_date" label="Expiry Date" hasFeedback>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    allowClear
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="client_reference"
                                label="Client Reference"
                                hasFeedback
                            >
                                <Input disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
                            <Form.Item name="bl_number" label="BL Number" hasFeedback>
                                <Input disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="detention_date"
                                label="Detention Date"
                                hasFeedback
                            >
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    allowClear
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="delivery_date"
                                label="Delivery Date"
                                hasFeedback
                                rules={[
                                    { required: true, message: "Please select a delivery date" },
                                ]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    allowClear
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="mb-3">
                            <Form.Item
                                name="priority"
                                label="Priority"
                                hasFeedback
                                rules={[
                                    { required: true, message: "Please select a priority" },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={[
                                        {
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <LowPriorityIcon width="24px" height="24px" style={{ marginRight: 8 }} />
                                                    <span>Low</span>
                                                </div>
                                            ),
                                            value: "Low"
                                        },
                                        {
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <MediumPriorityIcon width="24px" height="24px" style={{ marginRight: 8 }} />
                                                    <span>Medium</span>
                                                </div>
                                            ),
                                            value: "Medium"
                                        },
                                        {
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <HighPriorityIcon width="24px" height="24px" style={{ marginRight: 8 }} />
                                                    <span>High</span>
                                                </div>
                                            ),
                                            value: "High"
                                        }
                                    ]}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Tabs
                        activeKey={activeTabKey}
                        onChange={setActiveTabKey}
                        items={tabItems}
                    />
                </Form>
            </Drawer>
        </>
    );
};

export default SingleTripJobOrder;
