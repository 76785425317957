import React from 'react';
import { Row, Col, Card, Statistic, Tooltip } from 'antd';
import {
  CarOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined,
  FileTextOutlined,
  UserOutlined,
  TeamOutlined,
} from '@ant-design/icons';

const kpiData = [
  {
    title: 'Total Fleets',
    value: 150,
    icon: <CarOutlined />,
    color: '#1890ff',
    tooltip: 'Total number of fleet vehicles managed.',
  },
  {
    title: 'Active Fleets',
    value: 120,
    icon: <CheckCircleOutlined />,
    color: '#52c41a',
    tooltip: 'Number of fleets currently in operation.',
  },
  {
    title: 'Inactive Fleets',
    value: 30,
    icon: <PauseCircleOutlined />,
    color: '#f5222d',
    tooltip: 'Fleets that are not in use.',
  },
  {
    title: 'Total Job Orders',
    value: 500,
    icon: <FileTextOutlined />,
    color: '#faad14',
    tooltip: 'Combined count of multi-trip and single-trip job orders.',
  },
  {
    title: 'Active Dispatch Orders',
    value: 75,
    icon: <CarOutlined />,
    color: '#13c2c2',
    tooltip: 'Number of ongoing dispatch orders.',
  },
  {
    title: 'Total Customers',
    value: 200,
    icon: <UserOutlined />,
    color: '#eb2f96',
    tooltip: 'Number of registered customers.',
  },
  {
    title: 'Employees Count',
    value: 50,
    icon: <TeamOutlined />,
    color: '#722ed1',
    tooltip: 'Total number of employees, segmented by departments or designations.',
  },
];

function KPISection() {
  return (
    <Row gutter={[16, 16]}>
      {kpiData.map((kpi) => (
        <Col xs={24} sm={12} md={8} lg={6} xl={4} key={kpi.title}>
          <Tooltip title={kpi.tooltip} placement="topLeft">
            <Card>
              <Statistic
                title={
                  <span>
                    {kpi.title}
                  </span>
                }
                value={kpi.value}
                prefix={<span style={{ color: kpi.color, fontSize: '24px' }}>{kpi.icon}</span>}
                valueStyle={{ color: kpi.color, fontWeight: 'bold', fontSize: '32px' }}
              />
            </Card>
          </Tooltip>
        </Col>
      ))}
    </Row>
  );
}

export default KPISection;
