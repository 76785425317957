import React from 'react';
import { Card } from 'antd';
import Map from './Map';

function GeographicalOverview() {
  return (
    <Card title="Geographical Overview">
      <div style={{ height: '400px', width: '100%' }}>
        <Map />
      </div>
    </Card>
  );
}

export default GeographicalOverview;
