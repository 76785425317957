import React, { useEffect, useState } from "react";
import { Card, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { ContactsOutlined, DollarOutlined, FileFilled, FileTextOutlined, HomeOutlined, InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb';
import CustomerProfile from "./components/CustomerProfile";
import { rawDataCustomer, showCustomer } from "../../../../network/network";
import Mate from "../../../../components/Mate/Mate";
import GeneralInfo from "./components/GeneralInfo";
import CustomerContractsTab from "./components/CustomerContractsTab";
import Documents from "../../../../components/Documents";
import Contacts from "../../../../components/Contacts";
import CustomerOrders from "./components/CustomerOrders";

const CustomerDetails = () => {
    let { id } = useParams();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [customerRawData, steCustomerRawData] = useState([]);

    const fetchRawCustomerData = async() => {
        rawDataCustomer().then((response) => {
            steCustomerRawData(response.data.data);
        });
    }
    useEffect(() => {
        fetchRawCustomerData();
    }, []);

    const fetchCustomerData = async () => {
        setIsDataLoading(true);
        try {
            const response = await showCustomer(id);
            console.log(response);
            setCustomer(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerData();
    }, [id]);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    const commonCardStyles = {
        // boxShadow: '4px 4px 8px rgba(0,0,0,0.1)',
        // borderRadius: '8px',
        marginBottom: '20px',
        padding: '5px',
    };

    const tabItems = [
        {
            label: <><InfoCircleOutlined /> General Info</>,
            key: '1',
            children: <GeneralInfo customer={customer} cardStyle={commonCardStyles} />,
        },
        {
            label: <><DollarOutlined /> Orders</>,
            key: '2',
            children: <CustomerOrders customerId={id} />,
        },
        {
            label: <><FileTextOutlined /> Contracts</>,
            key: '3',
            children: <CustomerContractsTab customerId={id} />,
        },
        {
            label: <><ContactsOutlined /> Contacts</>,
            key: '4',
            children: <Contacts entityId={id} entityType={'Customer'} />,
        },
        {
            label: <><FileFilled /> Documents</>,
            key: '5',
            children: <Documents entityType={'Customer'} entityId={id} isExpiryDate={true} />,
        },
        {
            label: '👋 Mate',
            key: '6',
            children: <Mate entityType="Customer" entityId={id} />,
        },
    ];


    const breadcrumbRoutes = [
        {
            path: "/",
            breadcrumbName: "Home",
            icon: <HomeOutlined />,
        },
        {
            path: "/customers",
            breadcrumbName: "Customers",
            icon: <UserOutlined />,
        },
        {
            breadcrumbName: "Customer Details",
            icon: <FileFilled />,
        },
    ];

    return (
        <div>
            <CustomBreadcrumb items={breadcrumbRoutes} />
            <h1>Customer Details</h1>
            <CustomerProfile customer={customer} cardStyle={commonCardStyles} tags={customerRawData?.tags} onCustomerUpdate={fetchCustomerData} onRawDataUpdate={fetchRawCustomerData} />
            <Card>
                <Tabs items={tabItems} defaultActiveKey="1" onChange={handleTabChange} />
            </Card>
        </div>
    );
};

export default CustomerDetails;
