import React from 'react';
import { Card, List, Alert, Tag, Tooltip } from 'antd';
import {
  ClockCircleOutlined,
  WarningOutlined,
  ToolOutlined,
} from '@ant-design/icons';

const maintenanceData = [
  {
    id: '1',
    vehicle: 'Truck A',
    schedule: '2023-07-15',
    type: 'Oil Change',
    priority: 'High',
  },
  {
    id: '2',
    vehicle: 'Van B',
    schedule: '2023-07-18',
    type: 'Tire Rotation',
    priority: 'Medium',
  },
  {
    id: '3',
    vehicle: 'Truck C',
    schedule: '2023-07-20',
    type: 'Brake Inspection',
    priority: 'Low',
  },
];

function MaintenanceAlerts() {
  return (
    <Card title="Maintenance and Alerts">
      <Alert
        message="Critical Alert"
        description="Truck D is overdue for inspection. Please schedule immediately."
        type="error"
        showIcon
        icon={<WarningOutlined />}
        style={{ marginBottom: 16 }}
      />
      <List
        itemLayout="horizontal"
        dataSource={maintenanceData}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<ToolOutlined style={{ fontSize: '24px', color: '#1890ff' }} />}
              title={`${item.vehicle} - ${item.type}`}
              description={
                <div>
                  <span>Scheduled for: {item.schedule}</span>
                  <Tag color={
                    item.priority === 'High' ? 'red' :
                    item.priority === 'Medium' ? 'orange' :
                    'green'
                  } style={{ marginLeft: '8px' }}>
                    {item.priority}
                  </Tag>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default MaintenanceAlerts;
