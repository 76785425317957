import React, { useEffect, useState } from "react";
import { Card, Tabs, Tag, Typography } from "antd";
import { useParams } from "react-router-dom";
import { CheckCircleOutlined, ClockCircleOutlined, ContactsOutlined, DollarOutlined, ExclamationCircleOutlined, FileFilled, FileTextOutlined, HistoryOutlined, HomeOutlined, InfoCircleOutlined, SettingTwoTone, TruckFilled, TruckOutlined, UserOutlined } from "@ant-design/icons";
import FleetProfile from "./components/FleetProfile";
import GeneralInfo from "./components/GeneralInfo";
import { rawDataFleet, showFleet } from "../../../../../network/network";
import Mate from "../../../../../components/Mate/Mate";
import CustomBreadcrumb from "../../../../../components/CustomBreadcrumb";
import Documents from "../../../../../components/Documents";
import DriverHistory from "./components/DriverHistory";
import TripHistory from "./components/TripHistory";

const notifications = [
    { id: '1', title: 'Payment Received', description: 'Payment for invoice #1234 has been received.' },
    { id: '2', title: 'New Contract', description: 'A new contract has been assigned to you.' },
    { id: '3', title: 'Service Alert', description: 'Scheduled maintenance will occur this weekend.' },
    // Add more notifications here
];

const getStatusTag = (status) => {
    switch (status) {
        case 'Active':
            return <Tag icon={<CheckCircleOutlined />} color="success">Active</Tag>;
        case 'Completed':
            return <Tag icon={<ClockCircleOutlined />} color="processing">Completed</Tag>;
        case 'Expired':
            return <Tag icon={<ExclamationCircleOutlined />} color="default">Expired</Tag>;
        default:
            return <Tag>{status}</Tag>;
    }
};

const contractsHistory = [
    { id: '1', contract: 'Service Agreement', date: '2022-01-01', status: 'Active' },
    { id: '2', contract: 'Maintenance Contract', date: '2021-05-15', status: 'Completed' },
    { id: '3', contract: 'Consultancy Contract', date: '2020-11-20', status: 'Expired' },
    // Add more dummy contracts history here
];

const FleetDetails = () => {
    let { id } = useParams();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [fleet, setFleet] = useState({});
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [rawFleetData, setRawFleetData] = useState([]);

    const fetchRawFleetData = async() => {
        setIsDataLoading(true);
        try {
            rawDataFleet().then((response) => {
                setRawFleetData(response.data.data);
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    }

    useEffect(() => {
        fetchRawFleetData();
    }, []);

    const fetchFleetData = async () => {
        setIsDataLoading(true);
        try {
            const response = await showFleet(id);
            setFleet(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchFleetData();
    }, [id]);

    const fleetDetails = [
        { key: '1', property: 'Account ID', value: '123456' },
        { key: '2', property: 'Signup Date', value: '2020-01-01' },
        // Add more customer details here
    ];

    const upcomingMaintenance = [
        { id: '1', icon: <TruckOutlined style={{ color: '#e28273', backgroundColor: '#fcf3f2', padding: '5px', borderRadius: '10%', fontSize: '20px' }} />, title: 'Brakes Repair', date: '3 months overdue' },
        { id: '2', icon: <TruckOutlined style={{ color: '#e28273', backgroundColor: '#fcf3f2', padding: '5px', borderRadius: '10%', fontSize: '20px' }} />, title: 'Filter Replacement', date: '3 months overdue' },
        { id: '3', icon: <TruckOutlined style={{ color: '#e28273', backgroundColor: '#fcf3f2', padding: '5px', borderRadius: '10%', fontSize: '20px' }} />, title: 'Planned Inspection', date: '3 months overdue' },
        { id: '3', icon: <TruckOutlined style={{ color: '#e28273', backgroundColor: '#fcf3f2', padding: '5px', borderRadius: '10%', fontSize: '20px' }} />, title: 'Rotate Tyres', date: '3 months overdue' },
        { id: '3', icon: <TruckOutlined style={{ color: '#e28273', backgroundColor: '#fcf3f2', padding: '5px', borderRadius: '10%', fontSize: '20px' }} />, title: 'Planned Inspection', date: '3 months overdue' },
        // Add more dummy upcoming events here
    ];

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    const commonCardStyles = {
        // boxShadow: '4px 4px 8px rgba(0,0,0,0.1)',
        // borderRadius: '8px',
        marginBottom: '20px',
        padding: '5px',
        border: '1px solid #f0f0f0',
        borderTop: '0px',
        borderBottom: '0px',
    };


  const center = {
    lat: -34.397,
    lng: 150.644
  };

    const tabItems = [
        {
            label: <><InfoCircleOutlined /> General Info</>,
            key: '1',
            children: <GeneralInfo fleet={fleet} rawFleetData={rawFleetData} cardStyle={commonCardStyles} notifications={notifications} contractsHistory={contractsHistory} getStatusTag={getStatusTag} fleetDetails={fleetDetails} upcomingMaintenance={upcomingMaintenance} center={center} />,
        },
        {
            label: <><TruckFilled /> Trip History</>,
            key: '2',
            children: <TripHistory fleetId={id} />,
        },
        {
            label: <><FileFilled /> Documents</>,
            key: '3',
            children: <Documents entityType={"Fleet"} entityId={id} isExpiryDate={true} moduleType={'fleet'} />,
        },
        {
            label: <><UserOutlined /> Drivers History</>,
            key: '4',
            children: <DriverHistory drivers={fleet?.drivers} />,
        },
        {
            label: <><SettingTwoTone /> Manitenance</>,
            key: '5',
            children: <></>,
        },
        {
            label: '👋 Mate',
            key: '6',
            children: <Mate entityType="Fleet" entityId={id} />,
        },
    ];


    const breadcrumbRoutes = [
        {
            path: "/",
            breadcrumbName: "Home",
            icon: <HomeOutlined />,
        },
        {
            path: "/fleets",
            breadcrumbName: "Fleets",
            icon: <UserOutlined />,
        },
        {
            breadcrumbName: "Fleet Details",
            icon: <FileFilled />,
        },
    ];

    return (
        <div>
            <CustomBreadcrumb items={breadcrumbRoutes} />
            <h1>Fleet Details</h1>
            <FleetProfile cardStyle={commonCardStyles} fleet={fleet} rawFleetData={rawFleetData} onFleetUpdate={fetchFleetData} onRawDataUpdate={fetchRawFleetData} />
            <Card>
                <Tabs items={tabItems} defaultActiveKey="1" onChange={handleTabChange} />
            </Card>
        </div>
    );
};

export default FleetDetails;
