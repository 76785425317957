import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Content/Home/Home";
import {
  CarOutlined,
  GlobalOutlined,
  HomeOutlined,
  SettingOutlined,
  TeamOutlined,
  TruckFilled,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { AppContext } from "../context/AppContextProvider";
import { filterPagesByAddons } from "../utils.js";
import Services from "../pages/Content/GlobalConfiguration/Services/Services.js";
import Categories from "../pages/Content/GlobalConfiguration/Categories/Categories.js";
import Currencies from "../pages/Content/GlobalConfiguration/Currencies/Currencies.js";
import LoadType from "../pages/Content/GlobalConfiguration/Loadtypes/Loadtypes.js";
import Location from "../pages/Content/GlobalConfiguration/Locations/Locations.js";
import Departments from "../pages/Content/EmployeeConfigurations/Departments/Departments.js";
import Designations from "../pages/Content/EmployeeConfigurations/Designations/Designations.js";
import DocumentType from "../pages/Content/EmployeeConfigurations/DocumentType/DocumentType.js";
import Employees from "../pages/Content/EmployeeConfigurations/Employees/Employees.js";
import Statuses from "../pages/Content/FleetConfigurations/Statuses/Statuses.js";
import DocumentTypes from "../pages/Content/FleetConfigurations/DocumentType/DocumentTypes.js";
import FleetTypes from "../pages/Content/FleetConfigurations/FleetType/FleetType.js";
import TrailerTypes from "../pages/Content/FleetConfigurations/TrailerTypes/index.js";
import Hubs from "../pages/Content/FleetConfigurations/Hubs/Hubs.js";
import Fleets from "../pages/Content/FleetConfigurations/Fleets/Fleets.js";
import Customers from "../pages/Content/CustomerConfiguration/Customers/Customers.js";
import FleetDocument from "../pages/Content/FleetConfigurations/FleetDocument/FleetDocument.js";
import CustomerContract from "../pages/Content/CustomerConfiguration/CustomerContracts/CustomerContracts.js";
import Tags from "../pages/Content/GlobalConfiguration/Tags/Tags.js";
import FleetMakes from "../pages/Content/FleetConfigurations/FleetMakes/FleetMakes.js";
import CustomerDetails from "../pages/Content/CustomerConfiguration/CustomerDetails/CustomerDetails.js";
import FleetDetails from "../pages/Content/FleetConfigurations/Fleets/FleetDetails/FleetDetails.js";
import EmployeeDetails from "../pages/Content/EmployeeConfigurations/Employees/EmployeeDetails/EmployeeDetails.js";
import SingleTripJobOrder from "../pages/Content/JobOrders/SingleTrip/SingleTripJobOrders.js";
import DispatchOrder from "../pages/Content/DispatchManagement/DispatchOrders/DispatchOrders.js";
import MultiTripJobOrder from "../pages/Content/JobOrders/MultiTrip/MultiTripJobOrders.js";

export const ContentPages = [
  {
    path: "/",
    name: "home",
    text: "menu_home",
    component: Home,
    icon: HomeOutlined,
    showInSider: true,
  },
  {
    name: "jobOrders",
    text: "Job Orders",
    icon: SettingOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/single/trips",
        name: "singleTrips",
        text: "Single Trips",
        component: SingleTripJobOrder,
        icon: TruckFilled,
        showInSider: true,
      },
      {
        path: "/multi/trips",
        name: "multiTrips",
        text: "Multi Trips",
        component: MultiTripJobOrder,
        icon: TruckFilled,
        showInSider: true,
      },
    ],
  },
  {
    name: "dispatchManagement",
    text: "Dispatch",
    icon: TruckFilled,
    showInSider: true,
    subMenus: [
      {
        path: "/dispatch/orders",
        name: "dispatchOrders",
        text: "Dispatch Orders",
        component: DispatchOrder,
        icon: TruckFilled,
        showInSider: true,
      },
    ],
  },
  {
    name: "employeeConfiguration",
    text: "Employee",
    icon: UsergroupAddOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/employees",
        name: "employees",
        text: "Employees",
        component: Employees,
        icon: TeamOutlined,
        showInSider: true,
      },
      {
        path: "/departments",
        name: "departments",
        text: "Departments",
        component: Departments,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/designation",
        name: "designation",
        text: "Designations",
        component: Designations,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/documentType",
        name: "documentType",
        text: "Document Type",
        component: DocumentType,
        icon: GlobalOutlined,
        showInSider: true,
      },
    ],
  },
  {
    name: "fleetConfiguration",
    text: "Fleet",
    icon: CarOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/fleets",
        name: "fleets",
        text: "Vehicles",
        component: Fleets,
        icon: UserOutlined,
        showInSider: true,
      },
      {
        path: "/fleetMakes",
        name: "fleetMakes",
        text: "Fleet Makes",
        component: FleetMakes,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/fleetDocument",
        name: "fleetDocument",
        text: "Fleet Document",
        component: FleetDocument,
        icon: UserOutlined,
        showInSider: true,
      },
      {
        path: "/status",
        name: "status",
        text: "Status",
        component: Statuses,
        showInSider: true,
      },
      {
        path: "/documentTypes",
        name: "documentTypes",
        text: "Document Types",
        component: DocumentTypes,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/fleetType",
        name: "fleetType",
        text: "Fleet Type",
        component: FleetTypes,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/trailerType",
        name: "trailerType",
        text: "Trailer Type",
        component: TrailerTypes,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/hubs",
        name: "hubs",
        text: "Hubs",
        component: Hubs,
        icon: GlobalOutlined,
        showInSider: true,
      },
    ],
  },

  {
    name: "customerManagement",
    text: "Customer",
    icon: UserOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/customers",
        name: "customers",
        text: "Customers",
        component: Customers,
        icon: UserOutlined,
        showInSider: true,
      },
      {
        path: "/customerContracts",
        name: "customerContracts",
        text: "Customer Contracts",
        component: CustomerContract,
        icon: UserOutlined,
        showInSider: true,
      },
    ],
  },
  {
    name: "configuration",
    text: "Global Configuration",
    icon: GlobalOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/services",
        name: "services",
        text: "menu_services",
        component: Services,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/categories",
        name: "categories",
        text: "menu_categories",
        component: Categories,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/currencies",
        name: "currencies",
        text: "menu_currencies",
        component: Currencies,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/loadTypes",
        name: "loadTypes",
        text: "menu_loadType",
        component: LoadType,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/location",
        name: "location",
        text: "menu_locations",
        component: Location,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/tags",
        name: "tags",
        text: "menu_tags",
        component: Tags,
        icon: GlobalOutlined,
        showInSider: true,
      },
    ],
  },
];

const AppRoutes = () => {
  const { authUser } = useContext(AppContext);

  return (
    <>
      <Routes>
        <Route exact path="/home" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Home />} />
        <Route path="/fleets/:id" element={<FleetDetails />} />
        <Route path="/customers/:id" element={<CustomerDetails />} />
        <Route path="/customerContracts/:contractId"  element={<CustomerContract />} />
        <Route path="/employees/:id" element={<EmployeeDetails />} />

        {filterPagesByAddons(ContentPages, authUser).map(function (page, i) {
          if (page.component) {
            return (
              <Route
                exact
                path={page.path}
                name={page.name}
                element={<page.component />}
                key={page.name}
              />
            );
          } else {
            let subRoutes =
              page.subMenus &&
              filterPagesByAddons(page.subMenus, authUser).map(function (
                subPage,
                i
              ) {
                if (subPage.component) {
                  return (
                    <Route
                      exact
                      path={subPage.path}
                      name={subPage.name}
                      element={<subPage.component />}
                      key={subPage.name}
                    />
                  );
                }
                return false;
              });
            return subRoutes;
          }
        })}
      </Routes>
    </>
  );
};

export default AppRoutes;
