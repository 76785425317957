import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Space, Table, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, TruckFilled } from '@ant-design/icons';
import { addFleetType, deleteFleetType, getFleetTypes, updateFleetType } from '../../../../network/network';
import NotificationWithIcon from '../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../components/ImageUpload';

const { Title } = Typography;
const { Option } = Select;

const STATUS_OPTIONS = [
    { label: 'Active', value: 'Active', color: '#52c41a' },
    { label: 'Inactive', value: 'Inactive', color: '#ff4d4f' }
];

const FleetTypeForm = ({ form, image, setImage }) => (
    <Form form={form} layout="vertical" requiredMark="optional">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <ImageUpload
                name="image"
                callbackSetImageFile={setImage}
                image={image}
            />

            <Form.Item
                name="name"
                label="Fleet Type Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input placeholder="Enter fleet type name" />
            </Form.Item>

            <Form.Item
                name="status"
                label="Status"
                initialValue="Active"
                rules={[{ required: true, message: 'Status is required' }]}
            >
                <Select>
                    {STATUS_OPTIONS.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Space>
    </Form>
);

const FleetTypes = () => {
    const [form] = Form.useForm();
    const [fleetTypes, setFleetTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [modalConfig, setModalConfig] = useState({
        visible: false,
        title: '',
        editingId: null
    });

    const fetchFleetTypes = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getFleetTypes();
            setFleetTypes(response?.data?.data?.fleetTypes || []);
        } catch (error) {
            NotificationWithIcon(
                'error',
                'Error',
                'Failed to fetch fleet types. Please try again.'
            );
            setFleetTypes([]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchFleetTypes();
    }, [fetchFleetTypes]);

    const handleAdd = () => {
        form.resetFields();
        setImage(null);
        setModalConfig({
            visible: true,
            title: 'Add New Fleet Type',
            editingId: null
        });
    };

    const handleEdit = (record) => {
        form.setFieldsValue({
            name: record.name,
            status: record.status
        });
        setImage(record.image);
        setModalConfig({
            visible: true,
            title: 'Edit Fleet Type',
            editingId: record.id
        });
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this fleet type?',
            content: 'This action cannot be undone.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, keep it',
            onOk: async () => {
                try {
                    await deleteFleetType(id);
                    NotificationWithIcon(
                        'success',
                        'Success',
                        'Fleet type deleted successfully'
                    );
                    fetchFleetTypes();
                } catch (error) {
                    NotificationWithIcon(
                        'error',
                        'Error',
                        'Failed to delete fleet type. Please try again.'
                    );
                }
            }
        });
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const { editingId } = modalConfig;

            const formData = new FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined) {
                    formData.append(key, values[key]);
                }
            });

            if (image && image instanceof File) {
                formData.append('image', image);
            }

            if (editingId) {
                await updateFleetType(formData, editingId);
            } else {
                await addFleetType(formData);
            }

            NotificationWithIcon(
                'success',
                'Success',
                editingId
                    ? 'Fleet type updated successfully'
                    : 'Fleet type added successfully'
            );

            setModalConfig({ visible: false, title: '', editingId: null });
            form.resetFields();
            setImage(null);
            fetchFleetTypes();
        } catch (error) {
            NotificationWithIcon(
                'error',
                'Error',
                error?.response?.data?.message || 'Failed to save fleet type. Please try again.'
            );
        }
    };

    const columns = [
        {
            title: 'Image',
            align: 'center',
            width: '100px',
            render: (record) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {record.image ? (
                        <img
                            src={record.image}
                            alt="fleet type"
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                        />
                    ) : (
                        <TruckFilled style={{ fontSize: '40px' }} />
                    )}
                </div>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: status => {
                const statusOption = STATUS_OPTIONS.find(opt => opt.value === status);
                return (
                    <span style={{
                        color: statusOption?.color,
                        fontWeight: 500
                    }}>
                        {status}
                    </span>
                );
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(record.id)}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <Card>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Title level={4} style={{ margin: 0 }}>
                        Fleet Types
                    </Title>
                    <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={handleAdd}
                    >
                        Add Fleet Type
                    </Button>
                </Space>

                <Table
                    columns={columns}
                    dataSource={Array.isArray(fleetTypes) ? fleetTypes : []}
                    rowKey="id"
                    loading={loading}
                />

                <Modal
                    title={modalConfig.title}
                    open={modalConfig.visible}
                    onOk={handleModalSubmit}
                    onCancel={() => {
                        setModalConfig({ visible: false, title: '', editingId: null });
                        form.resetFields();
                        setImage(null);
                    }}
                    width={600}
                    destroyOnClose
                >
                    <FleetTypeForm
                        form={form}
                        image={image}
                        setImage={setImage}
                    />
                </Modal>
            </Space>
        </Card>
    );
};

export default FleetTypes;
