import React from 'react';
import { Card, List, Avatar, Badge, Tooltip } from 'antd';
import { NotificationOutlined, CarOutlined, FileSyncOutlined } from '@ant-design/icons';

const notifications = [
  {
    id: '1',
    type: 'Maintenance',
    title: 'Truck A Overdue for Oil Change',
    description: 'Truck A has not had an oil change in 6 months.',
    time: '2 hours ago',
    status: 'urgent',
  },
  {
    id: '2',
    type: 'Dispatch',
    title: 'New Dispatch Order JO-006',
    description: 'Dispatch order JO-006 has been assigned to Driver B.',
    time: '1 day ago',
    status: 'info',
  },
  {
    id: '3',
    type: 'System',
    title: 'System Maintenance Scheduled',
    description: 'System maintenance will occur on 2023-07-25 from 01:00 AM to 03:00 AM.',
    time: '3 days ago',
    status: 'normal',
  },
];

const getIcon = (type) => {
  switch (type) {
    case 'Maintenance':
      return <CarOutlined style={{ color: '#faad14', fontSize: '24px' }} />;
    case 'Dispatch':
      return <FileSyncOutlined style={{ color: '#52c41a', fontSize: '24px' }} />;
    case 'System':
      return <NotificationOutlined style={{ color: '#1890ff', fontSize: '24px' }} />;
    default:
      return <NotificationOutlined style={{ color: '#d9d9d9', fontSize: '24px' }} />;
  }
};

const getStatus = (status) => {
  switch (status) {
    case 'urgent':
      return 'red';
    case 'info':
      return 'blue';
    case 'normal':
      return 'green';
    default:
      return 'gray';
  }
};

function NotificationPanel() {
  return (
    <Card title="Notifications" bordered>
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Badge color={getStatus(item.status)} dot><Avatar icon={getIcon(item.type)} /></Badge>}
              title={<strong>{item.title}</strong>}
              description={
                <Tooltip title={item.description}>
                  <span>{item.description.length > 50 ? `${item.description.substring(0, 50)}...` : item.description}</span>
                </Tooltip>
              }
            />
            <div>{item.time}</div>
          </List.Item>
        )}
      />
    </Card>
  );
}

export default NotificationPanel;
