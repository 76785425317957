import React from 'react';
import { Card, List, Avatar, Tag, Button, Tooltip } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, ToolOutlined } from '@ant-design/icons';

const tasks = [
  {
    id: 'T-001',
    title: 'Oil Change for Truck B',
    assignedTo: 'John Doe',
    dueDate: '2023-07-20',
    status: 'Pending',
  },
  {
    id: 'T-002',
    title: 'Dispatch Order JO-007',
    assignedTo: 'Jane Smith',
    dueDate: '2023-07-18',
    status: 'In Progress',
  },
  {
    id: 'T-003',
    title: 'Brake Inspection for Truck C',
    assignedTo: 'Bob Johnson',
    dueDate: '2023-07-22',
    status: 'Pending',
  },
  {
    id: 'T-004',
    title: 'Dispatch Order JO-008',
    assignedTo: 'Alice Williams',
    dueDate: '2023-07-19',
    status: 'In Progress',
  },
];

const getStatusTag = (status) => {
  switch (status) {
    case 'Pending':
      return <Tag color="orange">{status}</Tag>;
    case 'In Progress':
      return <Tag color="blue">{status}</Tag>;
    case 'Completed':
      return <Tag color="green">{status}</Tag>;
    default:
      return <Tag>{status}</Tag>;
  }
};

function TaskAssignments() {
  return (
    <Card title="Task Assignments" bordered>
      <List
        itemLayout="horizontal"
        dataSource={tasks}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Tooltip title="Mark as Completed" key="complete">
                <Button
                  type="link"
                  icon={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
                  disabled={item.status === 'Completed'}
                />
              </Tooltip>,
              <Tooltip title="View Details" key="details">
                <Button type="link" icon={<ToolOutlined />} />
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<ClockCircleOutlined />} />}
              title={
                <div>
                  <strong>{item.title}</strong> <span>ID: {item.id}</span>
                </div>
              }
              description={
                <div>
                  <span>Assigned To: {item.assignedTo}</span> | <span>Due Date: {item.dueDate}</span>
                </div>
              }
            />
            {getStatusTag(item.status)}
          </List.Item>
        )}
      />
    </Card>
  );
}

export default TaskAssignments;
