import React, { useState, useEffect } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Table,
    Popconfirm,
    message,
    Row,
    Col,
    Space,
    Drawer,
    Upload,
    Select,
    Menu,
    Dropdown,
    InputNumber,
    Tooltip,
    Tag,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
    addFleet,
    deleteFleet,
    updateFleet,
    getFleets,
    rawDataFleet,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
    DeleteTwoTone,
    EditTwoTone,
    EllipsisOutlined,
    EyeFilled,
    EyeOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import ImageUpload from "../../../../components/ImageUpload";
import "react-phone-input-2/lib/style.css";
import FleetStage from "../../../../constants/FleetStage";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
// import FleetDetails from "../FleetDetails/FleetDetails";

const Fleets = () => {
    const ADD_FLEET = "Add Fleet";
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedFleet, setSelectedFleet] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [reloadTableData, setReloadTableData] = useState(false);
    const [fleetRawData, setFleetRawData] = useState([]);
    const [fleetData, setFleetData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [drawerTitle, setDrawerTitle] = useState(ADD_FLEET);
    const [fleetsForm] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        rawDataFleet().then((response) => {
            setFleetRawData(response.data.data);
        });
    }, []);

    const fetchData = async () => {
        setIsDataLoading(true);
        getFleets({
            page: currentPage,
            search: searchTerm
        })
            .then((response) => {
                setFleetData(response.data.data.fleets);
                const paginationData = response.data.data.pagination;
                setPagination({
                    current: paginationData.current_page,
                    pageSize: paginationData.per_page,
                    total: paginationData.total,
                    showSizeChanger: false,
                });
            })
            .finally(() => {
                setIsDataLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, reloadTableData]);

    const debouncedFetchData = debounce(() => {
        fetchData();
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel the debounced call if component unmounts or the value changes
        return () => debouncedFetchData.cancel();
    }, [searchTerm]);

    const handleViewDetails = (fleet) => {
        setSelectedFleet(fleet);
        navigate(`/fleets/${fleet.id}`, { fleet: fleet });
    };

    const showDrawer = () => {
        setDrawerTitle(ADD_FLEET);
        setImage(false);
        setDrawerVisible(true);
    };

    const handleAPIResponse = (response, type) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
            setReloadTableData(!reloadTableData);
            if (type === ADD_FLEET) {
                NotificationWithIcon(
                    "success",
                    "Fleet Added",
                    "Fleet added successfully!"
                );
            } else {
                NotificationWithIcon(
                    "success",
                    "Fleet Updated",
                    "Fleet updated successfully!"
                );
            }
        } else {
            NotificationWithIcon("error", "Something Went Wrong", data.message);
        }
        fleetsForm.resetFields();
        setDrawerVisible(false);
    };

    const handleAddEdit = async () => {
        try {
            const values = await fleetsForm.validateFields();
            const formData = new FormData();

            // Handle regular fields
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && !Array.isArray(values[key])) {
                    formData.append(key, values[key]);
                } else if (Array.isArray(values[key])) {
                    // Handle tags array
                    values[key].forEach((tag, index) => {
                        formData.append(`${key}[${index}]`, tag);
                    });
                }
            });

            if (image && image instanceof File) {
                formData.append("image", image);
            }

            if (drawerTitle === ADD_FLEET) {
                addFleet(formData)
                    .then((response) => handleAPIResponse(response, ADD_FLEET))
                    .catch((info) => {
                        console.log("API Failed:", info.response);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                updateFleet(formData, values.id)
                    .then((response) => {
                        console.log(response, "ffff");
                        handleAPIResponse(response, null);
                    })
                    .catch((info) => {
                        console.log("API Failed:", info.response);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const handleCancel = () => {
        fleetsForm.resetFields();
        setDrawerVisible(false);
    };

    const handleEditDrawer = (record) => {
        fleetsForm.resetFields();
        setDrawerTitle("Edit Fleet: " + record.make?.title);
        setImage(record.image);
        setSelectedFleet(record);
        fleetsForm.setFieldsValue({
            id: record.id,
            fleet_make_id: record.make?.id,
            model: record.model,
            license_plate: record.license_plate,
            year: Number(record.year),
            odometer: record.odometer,
            vin: record.vin,
            status_id: record.statuses?.id,
            category_id: record.category?.id,
            hub_id: record.hub?.id,
            driver_id: record.driver?.id,
            fleet_type_id: record.fleet_type?.id,
            trailer_type_id: record.trailer_type?.id,
            tags: record.tags,
        });
        setDrawerVisible(true);
    };

    const handleDelete = (id) => {
        deleteFleet(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    setReloadTableData(!reloadTableData);
                    NotificationWithIcon("success", "Fleet Deleted", data.message);
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const handleFleetsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const getStageColor = (stage) => {
        switch (stage) {
            case FleetStage.TRANSIT:
                return 'warning';
            case FleetStage.AVAILABLE:
                return 'success';
            case FleetStage.UNAVAILABLE:
                return 'error';
            default:
                return 'default';
        }
    };

    const getStatusColor = (statusName, type) => {
        if (type === 'tag') {
            switch (statusName?.toLowerCase()) {
                case 'inactive':
                    return 'processing';
                case 'active':
                    return 'success';
                default:
                    return 'cyan';
            }
        } else {
            switch (statusName?.toLowerCase()) {
                case 'inactive':
                    return '#1677ff';
                case 'active':
                    return '#52c41a';
                default:
                    return '#FFFFFF';
            }
        }
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return ((pagination.current - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: "Fleet Make",
            //   dataIndex: "name",
            render: (record) => {
                return record.make.title;
            },
        },
        {
            title: "License",
            dataIndex: "license_plate",
        },
        {
            title: "Model",
            dataIndex: "model",
        },
        {
            title: "Year",
            dataIndex: "year",
        },
        {
            title: "Odometer",
            dataIndex: "odometer",
        },
        {
            title: 'Stage',
            key: 'stage',
            width: '5px',
            render: (record) => {
                let color = getStageColor(record.stage, 'tag');

                return (
                        <Tag bordered={false} color={color}>{record.stage}</Tag>
                );
            },
        },
        {
            title: 'Status',
            key: 'statusColor',
            width: '5px',
            render: (record) => {
                let color = getStatusColor(record.statuses.name, 'tag');

                return (
                        <Tag bordered={false} color={color}>{record.statuses.name}</Tag>
                );
            },
        },
        {
            title: "Vin",
            dataIndex: "vin",
        },
        {
            title: "Action",
            align: "center",
            width: "150px",
            render: (record) => {
              return (
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Button
                    type="link"
                    onClick={() => {
                      handleViewDetails(record);
                    }}
                    title="View Details"
                    style={{
                        padding: 6,
                        fontSize: "18px",
                        borderRadius: "5px",
                        backgroundColor: "rgba(82, 196, 26, 0.2)",
                        display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                      }}
                  >
                    <EyeFilled style={{ color: "#52c41a" }} />
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      handleEditDrawer(record);
                    }}
                    title="Edit record"
                    style={{
                        padding: 6,
                        fontSize: "18px",
                        borderRadius: "5px",
                        backgroundColor: "rgba(24, 144, 255, 0.2)",
                        display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                      }}
                  >
                    <EditTwoTone twoToneColor="#1890ff" />
                  </Button>
                  <Popconfirm
                    title="Are you sure to delete this entity?"
                    onConfirm={() => handleDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteTwoTone
                      style={{
                        padding: 6,
                        fontSize: "18px",
                        borderRadius: "5px",
                        backgroundColor: "rgba(255, 0, 0, 0.2)",
                        display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                      }}
                      twoToneColor="#ff0000"
                      title="Delete Entity"
                    />
                  </Popconfirm>
                </div>
              );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Fleets"
                    ghost={false}
                    extra={[
                        <Button type="primary" onClick={showDrawer} key="add-fleet">
                            + Add Fleets
                        </Button>,
                    ]}
                />
            </Card>
            <Card>
            <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: '50%' }}
                />
                <Table
                    columns={tableColumns}
                    dataSource={fleetData}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={pagination} onChange={handleFleetsTableChange}
                />
            </Card>
            <Drawer
                title={drawerTitle}
                placement="right"
                onClose={handleCancel}
                open={drawerVisible}
                width={"calc(100% - 200px)"}
                styles={{ header: drawerTitle !== ADD_FLEET ? { borderBottom: `8px solid ${getStatusColor(selectedFleet?.statuses?.name, 'drawer')}` } : {} }}
                >
                <Form
                    name="add-form"
                    onFinish={handleAddEdit}
                    layout="vertical"
                    style={{ width: "100%", margin: "0" }}
                    form={fleetsForm}
                >
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 20,
                            lg: 20,
                            xl: 20,
                            xxl: 20,
                        }}
                    >
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <ImageUpload
                                name="product_image"
                                callbackSetImageFile={setImage}
                                image={image}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="fleet_make_id"
                                label="Fleet Make"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Fleet Make Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.fleet_makes?.map((fleet_make) => {
                                        return {
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    {fleet_make.image && (
                                                        <img
                                                            src={fleet_make.image}
                                                            alt={fleet_make.title}
                                                            style={{ width: 20, height: 20, objectFit: 'contain' }}
                                                        />
                                                    )}
                                                    <span>{fleet_make.title}</span>
                                                </div>
                                            ),
                                            value: fleet_make.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label?.props?.children?.[1]?.props?.children ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="license_plate"
                                label="License Plate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate License Number Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="model"
                                label="Model"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Model Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="year"
                                label="Year"
                                initialValue={new Date().getFullYear()}
                                rules={[
                                    {
                                        required: false,
                                        message: "Year is optional",
                                    },
                                    {
                                        type: 'number',
                                        min: 2000,
                                        max: 2050,
                                        message: "Year must be between 2000 and 2050",
                                    },
                                ]}
                                hasFeedback
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="odometer"
                                label="Odometer"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Odometer Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="vin"
                                label="Vin"
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="category_id"
                                label="Fleet Category"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Category Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.categories?.map((category) => {
                                        return {
                                            label: category.name,
                                            value: category.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="hub_id"
                                label="Hub"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Hubs Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.hubs?.map((hub) => {
                                        return {
                                            label: hub.name,
                                            value: hub.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="driver_id"
                                label="Driver"
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.drivers?.map((driver) => {
                                        return {
                                            label: driver.name,
                                            value: driver.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="status_id"
                                label="Status"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Status ID Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.statuses?.map((status) => {
                                        return {
                                            label: status.name,
                                            value: status.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="fleet_type_id"
                                label="Fleet Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Fleet Type ID Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.fleet_types?.map((fleet) => {
                                        return {
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    {fleet.image && (
                                                        <img
                                                            src={fleet.image}
                                                            alt={fleet.name}
                                                            style={{ width: 20, height: 20, objectFit: 'contain' }}
                                                        />
                                                    )}
                                                    <span>{fleet.name}</span>
                                                </div>
                                            ),
                                            value: fleet.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label?.props?.children?.[1]?.props?.children ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="trailer_type_id"
                                label="Trailer Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Trailer Type Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={fleetRawData.trailer_types?.map((type) => {
                                        return {
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    {type.image && (
                                                        <img
                                                            src={type.image}
                                                            alt={type.name}
                                                            style={{ width: 20, height: 20, objectFit: 'contain' }}
                                                        />
                                                    )}
                                                    <span>{type.name}</span>
                                                </div>
                                            ),
                                            value: type.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label?.props?.children?.[1]?.props?.children ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="tags"
                                label="Tags"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select or add tags",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    mode="tags"
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select or add tags"
                                    options={fleetRawData.tags?.map((tag) => ({
                                        label: tag.name,
                                        value: tag.id,
                                    }))}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="id" style={{ display: "none" }}>
                        <input type="hidden" />
                    </Form.Item>
                </Form>
                <Row justify="end" style={{ marginBottom: "20px" }}>
                    <Space size={20}>
                        <Button key="back" loading={loading} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleAddEdit}
                        >
                            {drawerTitle === ADD_FLEET ? "Add Fleet" : "Update"}
                        </Button>
                    </Space>
                </Row>
            </Drawer>
        </>
    );
};

export default Fleets;
