import React from 'react';
import { Row, Col, Card } from 'antd';
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const fleetUtilizationData = [
  { month: 'Jan', utilization: 65 },
  { month: 'Feb', utilization: 59 },
  { month: 'Mar', utilization: 80 },
  { month: 'Apr', utilization: 81 },
  { month: 'May', utilization: 56 },
  { month: 'Jun', utilization: 55 },
  { month: 'Jul', utilization: 40 },
];

const jobOrdersData = [
  { name: 'Multi-trip', value: 400 },
  { name: 'Single-trip', value: 300 },
];

const dispatchOrdersData = [
  { name: 'Completed', value: 50 },
  { name: 'In Progress', value: 30 },
  { name: 'Pending', value: 20 },
];

const employeeDistributionData = [
  { department: 'Operations', count: 20 },
  { department: 'Maintenance', count: 15 },
  { department: 'Admin', count: 10 },
  { department: 'Sales', count: 5 },
];

function ChartsSection() {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Card title="Fleet Utilization Over Time">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={fleetUtilizationData}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" label={{ value: 'Month', position: 'insideBottomRight', offset: 0 }} />
              <YAxis label={{ value: 'Utilization (%)', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend verticalAlign="top" height={36}/>
              <Line type="monotone" dataKey="utilization" name="Utilization" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card title="Job Orders Breakdown">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={jobOrdersData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#82ca9d"
                label
              />
              <Tooltip />
              <Legend verticalAlign="bottom" height={36}/>
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card title="Dispatch Orders Status">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={dispatchOrdersData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                label
              />
              <Tooltip />
              <Legend verticalAlign="bottom" height={36}/>
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card title="Employee Distribution by Department">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={employeeDistributionData}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="department" label={{ value: 'Department', position: 'insideBottom', offset: -5 }} />
              <YAxis label={{ value: 'Number of Employees', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend verticalAlign="top" height={36}/>
              <Bar dataKey="count" name="Employees" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
  );
}

export default ChartsSection;
