import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  message,
  Col,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
  DeleteTwoTone,
  EditTwoTone,
  LockOutlined,
  TruckFilled,
  UnlockOutlined,
} from "@ant-design/icons";
import { getFleetMakes, addFleetMake, updateFleetMake, deleteFleetMake } from "../../../../network/network";
import ImageUpload from "../../../../components/ImageUpload";

const FleetMakes = () => {
  const ADD_MAKES = "Add Fleet Make";
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [fleetMakesData, setFleetMakesData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_MAKES);
  const [fleekMakeForm] = Form.useForm();

  useEffect(() => {
    setIsDataLoading(true);
    getFleetMakes()
      .then((response) => {
        setFleetMakesData(response.data.data.fleet_makes);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reloadTableData]);

  const showModal = () => {
    // Set Modal Title
    setModalTitle("Add Fleet Make");
    // Show Modal
    setModalVisible(true);
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Reset Form Fields
      fleekMakeForm.resetFields();
      // Reset image state
      setImage(false);
      // Hide Modal
      setModalVisible(false);
      // Success notification
      if (type === ADD_MAKES) {
        NotificationWithIcon(
          "success",
          "Fleet Make Added",
          "Fleet Make added successfully!"
        );
      } else {
        NotificationWithIcon(
          "success",
          "Fleet Make Updated",
          "Fleet Make updated successfully!"
        );
      }
    } else {
      NotificationWithIcon(
        "error",
        "Something Went Wrong",
        data.message
      );
    }
  };

  const handleAddEdit = () => {
    fleekMakeForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        const formData = new window.FormData();
        Object.keys(values).forEach(key => {
            if (values[key] !== undefined) {
                formData.append(key, values[key]);
            }
        });
        if (image && image instanceof File) {
            formData.append("image", image);
        }
        // Sending Request to API
        if (modalTitle === ADD_MAKES || modalTitle === "Add Fleet Make") {
          addFleetMake(formData)
            .then((response) => handleAPIResponse(response, ADD_MAKES))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateFleetMake(formData, values.id)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("API Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    fleekMakeForm.resetFields();
    // Reset image state
    setImage(false);
    setModalVisible(false);
  };

  const handleEditModal = (record) => {
    fleekMakeForm.resetFields();
    // Set Modal Title
    setModalTitle("Edit Fleet Make : " + record.title);
    // Set Form Fields
    fleekMakeForm.setFieldsValue({
        id: record.id,
        title: record.title,
    });
    // Set image from record
    setImage(record.image || false);
    // Show Modal
    setModalVisible(true);
  };

  const handleDelete = (id) => {
    deleteFleetMake(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          // Success
          setReloadTableData(!reloadTableData);
          NotificationWithIcon("success", "Fleet Make Deleted", data.message);
        } else {
          // Error
          NotificationWithIcon("error", "Something Went Wrong", data.message);
        }
      })
      .catch((info) => {
        // Error
        NotificationWithIcon(
          "error",
          "Something Went Wrong",
          info.response.data.message
        );
      });
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
        title: "Image",
        align: "center",
        render: (record) => {
            const imageStyle = {
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                objectFit: "cover",
                display: "block",
                margin: "0 auto"
            };

            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {record.image ? (
                        <img
                            src={record.image}
                            alt="fleet make"
                            style={imageStyle}
                        />
                    ) : (
                        <TruckFilled style={{ fontSize: "40px" }} />
                    )}
                </div>
            );
        }
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Action",
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button
              type="link"
              onClick={() => {
                handleEditModal(record);
              }}
              title="Edit record"
              style={{
                padding: 6,
                fontSize: "18px",
                borderRadius: "5px",
                backgroundColor: "rgba(24, 144, 255, 0.2)",
                display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title="Are you sure to delete this entity? All related fleets will be deleted."
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteTwoTone
                style={{
                    padding: 6,
                    fontSize: "18px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                    display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                  }}
                twoToneColor="#ff0000"
                title="Delete Entity"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <PageHeader
          style={{ padding: "0" }}
          title="Fleet Makes"
          ghost={false}
          extra={[
            <Button type="primary" onClick={showModal} key="add-fleet-makes">
              + Add Fleet Make
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={fleetMakesData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
          bordered
        />
      </Card>
      {/* Add Edit Tag Modal */}
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        width={400}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_MAKES || modalTitle === "Add Fleet Make"
              ? "Add Fleet Make"
              : "update"}
          </Button>,
        ]}
      >
        <Form
          name="add-form"
          onFinish={handleAddEdit}
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={fleekMakeForm}
        >
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <ImageUpload
                name="image"
                callbackSetImageFile={setImage}
                image={image}
            />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Validate Fleet Make Required",
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          </Col>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FleetMakes;
