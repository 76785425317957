import "./App.less";
import { useEffect, useContext } from "react";
import { ConfigProvider, Spin } from "antd";
import ar_EG from "antd/lib/locale/ar_EG";
import en_GB from "antd/lib/locale/en_GB";
import Login from "../Auth/Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import { AppContext } from "../../context/AppContextProvider";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getMyProfile } from "../../network/network";
import { useTranslation } from "react-i18next";

const App = () => {
  const {
    authUser,
    setAuthUser,
    fullPageLoading,
    setFullPageLoading,
    setActiveMenu,
    appConfig,
  } = useContext(AppContext);
  let navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const navigateToLastVisitedPage = () => {
      if (window.location.pathname === "/") {
        const lastVisitedPage = JSON.parse(localStorage.getItem("last_visited_page"));
        if (lastVisitedPage?.key) {
          setActiveMenu(lastVisitedPage.key);
          navigate(lastVisitedPage.path || "/home");
        } else {
          navigate("/home");
        }
      } else {
        // Set active menu based on current path
        const lastVisitedPage = JSON.parse(localStorage.getItem("last_visited_page"));
        if (lastVisitedPage?.key) {
          setActiveMenu(lastVisitedPage.key);
        }
      }
    };

    const authenticateUser = () => {
        setFullPageLoading(true);
        getMyProfile()
          .then((response) => {
            if (response.data.success) {
              setAuthUser(response.data.user);
              localStorage.setItem("profile", JSON.stringify(response.data.user));
              navigateToLastVisitedPage();
            }
          })
          .finally(() => {
            setFullPageLoading(false);
          });
      };

    const token = localStorage.getItem("token");
    if (!authUser && token) {
      authenticateUser();
    } else if (!token) {
      navigate("/login");
    }
  }, [authUser, navigate, setActiveMenu, setAuthUser, setFullPageLoading]);

  useEffect(() => {
    if (appConfig.direction === "rtl" && i18n.language !== "ar-EG") {
      i18n.changeLanguage("ar-EG");
    } else if (appConfig.direction !== "rtl" && i18n.language !== "en-GB") {
      i18n.changeLanguage("en-GB");
    }
  }, [appConfig.direction, i18n]);

  return (
    <ConfigProvider
      direction={appConfig.direction}
      locale={appConfig.direction === "rtl" ? ar_EG : en_GB}
    >
      <div className={appConfig.direction === "rtl" ? "app_rtl" : "app_ltr"}>
        {fullPageLoading && (
          <div
            style={{
              textAlign: "center",
              paddingTop: "45vh",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              background: "#ffffff",
              zIndex: "999999",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!fullPageLoading && !authUser ? (
          <Routes>
            <Route
              exact
              path="/login"
              name="Login"
              element={<Login />}
              key="login"
            />
          </Routes>
        ) : (
          <Dashboard />
        )}
      </div>
    </ConfigProvider>
  );
};

export default App;
