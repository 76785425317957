import "../AuthStyle.css";
import { Form, Input, message, Button, Typography } from "antd";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
const { Title } = Typography;

const TruckSVG = () => (
  <svg
    viewBox="0 0 640 512"
    className="truck-svg"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-464 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm208-96H242.7c-16.6-28.6-47.2-48-82.7-48s-66.1 19.4-82.7 48H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h320c8.8 0 16 7.2 16 16v304zm160 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm96-96h-29.3c-16.6-28.6-47.2-48-82.7-48s-66.1 19.4-82.7 48H416v-80h64c8.8 0 16 7.2 16 16v64h144v32z"/>
  </svg>
);

const Login = () => {
  const { setAuthUser, setFullPageLoading } = useContext(AppContext);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (values) => {
    setFullPageLoading(true);
    userLogin(values)
      .then((response) => {
        if (response.data?.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("profile", JSON.stringify(response.data.user));
          setAuthUser(response.data.user);
          navigate("/home");
        } else {
          let error = response.response;
          if (error?.status === 422) {
            message.error(error.data.message);
          } else if (error?.status === 500) {
            let errorMessages = Object.values(error.data.data.errors);
            errorMessages.forEach(function (key, value) {
              let temp = Object.values(key);
              message.error(temp[0]);
            });
          } else {
            message.error(t("something_went_wrong"));
          }
        }
      })
      .finally(() => {
        setFullPageLoading(false);
      });
  };

  return (
    <div className="login_container">
      <div className="login_form_container">
        <div className="right">
          <div className="brand-text">Truckala</div>
          <TruckSVG />
          <div className="svg-overlay"></div>
          <div className="circles">
            <div className="circle circle-1"></div>
            <div className="circle circle-2"></div>
            <div className="circle circle-3"></div>
          </div>
        </div>
        <div className="left">
          <Form
            onFinish={onFinish}
            name="login-user-form"
            layout="vertical"
            className="form_container"
          >
            <div className="form-header">
              <Title level={2} className="login-title">
                {t("welcome_back")}
              </Title>
              <p className="login-subtitle">{t("login_to_continue")}</p>
            </div>

            <Form.Item
              label={t("email")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("validate_email_invalid"),
                },
                { type: "email", message: "Email is not valid!" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="input-icon" />}
                className="login_input"
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item
              label={t("password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("validate_password_required"),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="input-icon" />}
                className="login_input"
                placeholder="Enter your password"
              />
            </Form.Item>

            <div className="btn_div">
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" className="login-button">
                  {t("login")}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
