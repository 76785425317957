import React from 'react';
import { Card, Button, Row, Col, Tooltip } from 'antd';
import {
  CarOutlined,
  FileAddOutlined,
  UserAddOutlined,
  TeamOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function QuickAccessPanel() {
  const navigate = useNavigate();

  const actions = [
    {
      title: 'Add New Fleet',
      icon: <CarOutlined />,
      color: '#1890ff',
      route: '/fleets/add',
    },
    {
      title: 'Add New Job Order',
      icon: <FileAddOutlined />,
      color: '#52c41a',
      route: '/job-orders/add',
    },
    {
      title: 'Add New Customer',
      icon: <UserAddOutlined />,
      color: '#f5222d',
      route: '/customers/add',
    },
    {
      title: 'Add New Employee',
      icon: <TeamOutlined />,
      color: '#faad14',
      route: '/employees/add',
    },
  ];

  return (
    <Card title="Quick Access" bordered>
      <Row gutter={[16, 16]}>
        {actions.map((action) => (
          <Col xs={12} sm={6} key={action.title}>
            <Tooltip title={action.title}>
              <Button
                type="primary"
                icon={<PlusOutlined style={{ marginRight: 8 }} />}
                block
                style={{ backgroundColor: action.color, borderColor: action.color }}
                onClick={() => navigate(action.route)}
              >
                {action.title}
              </Button>
            </Tooltip>
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default QuickAccessPanel;
