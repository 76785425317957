import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { CarOutlined, UserOutlined } from '@ant-design/icons';

// Fix for default marker icon issues with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Custom icon based on fleet status
const createIcon = (status) => {
  let iconUrl = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png';
  if (status === 'active') {
    iconUrl = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
  } else if (status === 'inactive') {
    iconUrl = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
  }
  return new L.Icon({
    iconUrl,
    iconRetinaUrl: iconUrl,
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
};

const fleetLocations = [
  { id: 1, name: 'Truck A', position: [51.505, -0.09], status: 'active' },
  { id: 2, name: 'Van B', position: [51.51, -0.1], status: 'inactive' },
  { id: 3, name: 'Truck C', position: [51.515, -0.09], status: 'active' },
];

function Map() {
  return (
    <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
        OpenStreetMap</a> contributors'
      />
      {fleetLocations.map((fleet) => (
        <Marker key={fleet.id} position={fleet.position} icon={createIcon(fleet.status)}>
          <Popup>
            <strong>{fleet.name}</strong><br />
            Status: {fleet.status.charAt(0).toUpperCase() + fleet.status.slice(1)}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default Map;
