import { Form, Input, Select, Col, Row, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const TransportationForm = ({ customerRawData, disabled, editMode, initialValues, isNoInvoice }) => {
    const { locations, fleet_types, load_types } = customerRawData;

    return (
        <>
            <h3 style={{ position: "relative" }}>Transportation Contract</h3>
            <hr style={{ margin: "8px 0" }} />
            <Form.List
                name="transportations"
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Row key={key} gutter={{ xs: 8, sm: 16, md: 20, lg: 24, xl: 24, xxl: 24 }} align="bottom">
                                {/* From City */}
                                <Col span={4}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'from_location_id']}
                                        label="From City"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a from city' }]} // Add this line
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            options={locations.map(location => ({
                                                label: location.location,
                                                value: location.id,
                                            }))}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* To City */}
                                <Col span={4}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'to_location_id']}
                                        label="To City"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a to city' }]} // Add this line
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            options={locations.map(location => ({
                                                label: location.location,
                                                value: location.id,
                                            }))}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* Fleet Type */}
                                <Col span={3}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'fleet_type_id']}
                                        label="Fleet Type"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a fleet type' }]} // Add this line
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            options={fleet_types.map(fleet_type => ({
                                                label: fleet_type.name,
                                                value: fleet_type.id,
                                            }))}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* Load Type */}
                                <Col span={3}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'load_type_id']}
                                        label="Load Type"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a load type' }]} // Add this line
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            options={load_types.map(load_type => ({
                                                label: load_type.type,
                                                value: load_type.id,
                                            }))}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* Charges */}
                                <Col span={3}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'charges']}
                                        label="Charges"
                                        hasFeedback
                                        rules={[{ required: !isNoInvoice, message: 'Please input the charges' }]}
                                    >
                                        <Input type="number" disabled={disabled || isNoInvoice} />
                                    </Form.Item>
                                </Col>
                                {/* Transportation Mode */}
                                <Col span={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'transportation_mode']}
                                        label="Transportation Mode"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a transportation mode' }]} // Add this line
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            options={[
                                                { label: 'Internal', value: 'Internal' },
                                                { label: 'External', value: 'External' }
                                            ]}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* Minus Button - Do not show for the first row */}
                                {index > 0 && !disabled && (
                                    <Col span={2}>
                                        <Button
                                            type="danger"
                                            onClick={() => remove(name)}
                                            icon={<MinusCircleOutlined />}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '80px',
                                            }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        ))}
                        {!disabled && (
                            <Row>
                                <Col span={12} offset={6}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Transportation
                                    </Button>
                                    <div style={{ marginBottom: "20px" }}></div>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </Form.List>
        </>
    );
};

export default TransportationForm;
