import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Tag, Typography, Input, Select, Form } from 'antd';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, TruckFilled, UserOutlined } from '@ant-design/icons';
import { BuildingIcon } from '../../../../../../components/CustomIcons';
import { updateFleet } from '../../../../../../network/network';
import NotificationWithIcon from '../../../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../../../components/ImageUpload';
import FleetStage from "../../../../../../constants/FleetStage";
const { Text } = Typography;
const { Option } = Select;

const FleetProfile = ({ fleet, cardStyle, rawFleetData, onFleetUpdate, onRawDataUpdate }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [image, setImage] = useState(false);
    const [fleetData, setFleetData] = useState({
        ...fleet,
    });

    useEffect(() => {
        setFleetData({
            ...fleet,
        });
    }, [fleet])

    const handleEdit = () => {
        setEditMode(true);
        setImage(fleetData.image);
        editForm.setFieldsValue({
            id: fleetData.id,
            license_plate: fleetData.license_plate,
            fleet_make_id: fleetData.make.id,
            fleet_type_id: fleetData.fleet_type.id,
            status_id: fleetData.statuses.id,
            tags: fleetData.tags,
            hub_id: fleetData.hub.id,
            stage: 'On Route',
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Fleet updated successfully`, `Fleet updated Successfully`);
            editForm.resetFields();
            onRawDataUpdate();
            onFleetUpdate();
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = await editForm.validateFields();
            const formData = new window.FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && !Array.isArray(values[key])) {
                    formData.append(key, values[key]);
                } else if (Array.isArray(values[key])) {
                    values[key].forEach((tag, index) => {
                        formData.append(`${key}[${index}]`, tag);
                    });
                }
            });
            if (image && image instanceof File) {
                formData.append("image", image);
            }
            const response = await updateFleet(formData, fleet.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    const handleRemoveTag = (index) => {
        const newTags = [...fleetData.tags];
        newTags.splice(index, 1);
        setFleetData({ ...fleetData, tags: newTags });
    };

    const getStageColor = (stage) => {
        switch (stage) {
            case FleetStage.TRANSIT:
                return 'warning';
            case FleetStage.AVAILABLE:
                return 'success';
            case FleetStage.UNAVAILABLE:
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <Card style={{ width: '100%', overflow: 'hidden', ...cardStyle }}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                {/* Top section with image and basic info */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '0px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {/* Image Column */}
                        <div style={{ marginRight: '15px' }}>
                            {editMode ? (
                                <ImageUpload
                                    name="fleet_image"
                                    callbackSetImageFile={setImage}
                                    image={image}
                                />
                            ) : (fleetData?.image ? (
                                <img
                                    src={fleetData?.image}
                                    alt="fleet picture"
                                    style={{ width: '100px', padding: '10px 0px', borderRadius: '5%', border: '1px dashed #d9d9d9' }}
                                />
                            ) : (
                                <TruckFilled style={{ fontSize: '80px' }} />
                            ))}
                        </div>

                        {/* Basic Info Column */}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div>
                                <Text strong>Make</Text>
                                {editMode ? (
                                    <Form.Item name={'fleet_make_id'} rules={[{ required: true }]}>
                                        <Select style={{ width: 200 }}>
                                            {rawFleetData.fleet_makes.map((make) => (
                                                <Option key={make.id} value={make.id}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        {make.image && (
                                                            <img src={make.image} alt={make.title} style={{ width: 20, height: 20, objectFit: 'contain' }} />
                                                        )}
                                                        <span>{make.title}</span>
                                                    </div>
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                                        {fleetData?.make?.image && (
                                            <img src={fleetData?.make?.image} alt={fleetData?.make?.title} style={{ width: 20, height: 20, objectFit: 'contain' }} />
                                        )}
                                        <span>{fleetData?.make?.title}</span>
                                    </div>
                                )}
                            </div>

                            <div>
                                <Text strong>License Plate</Text>
                                {editMode ? (
                                    <Form.Item name={'license_plate'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                        <Input style={{ width: 200 }} />
                                    </Form.Item>
                                ) : (
                                    <div style={{ marginTop: '5px' }}>{fleetData?.license_plate}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Edit/Save Buttons */}
                    <div>
                        {editMode ? (
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Button onClick={handleSave} type="primary">
                                    <SaveOutlined /> Save
                                </Button>
                                <Button onClick={handleCancel}>
                                    <CloseOutlined /> Cancel
                                </Button>
                            </div>
                        ) : (
                            <Button onClick={handleEdit} type="primary">
                                <EditOutlined /> Edit Fleet
                            </Button>
                        )}
                    </div>
                </div>

                <Divider style={{ margin: '20px 0' }} />

                {/* Details Grid */}
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                    <div>
                        <Text strong>Stage</Text>
                        <div style={{ marginTop: '5px' }}>
                            <Tag bordered={false} color={getStageColor(fleetData.stage)}>{fleetData.stage}</Tag>
                        </div>
                    </div>

                    <div>
                        <Text strong>Fleet Type</Text>
                        <div style={{ marginTop: '5px' }}>
                            {editMode ? (
                                <Form.Item name={'fleet_type_id'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                    <Select style={{ width: '100%' }}>
                                        {rawFleetData.fleet_types.map((type) => (
                                            <Option key={type.id} value={type.id}>{type.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Tag bordered={false} color='blue'>{fleetData?.fleet_type?.name}</Tag>
                            )}
                        </div>
                    </div>

                    <div>
                        <Text strong>Hub</Text>
                        <div style={{ marginTop: '5px' }}>
                            {editMode ? (
                                <Form.Item name={'hub_id'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                    <Select style={{ width: '100%' }}>
                                        {rawFleetData.hubs.map((hub) => (
                                            <Option key={hub.id} value={hub.id}>{hub.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Tag bordered={false} color='blue'>{fleetData?.hub?.name}</Tag>
                            )}
                        </div>
                    </div>

                    <div>
                        <Text strong>Status</Text>
                        <div style={{ marginTop: '5px' }}>
                            {editMode ? (
                                <Form.Item name={'status_id'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                    <Select style={{ width: '100%' }}>
                                        {rawFleetData.statuses.map((status) => (
                                            <Option key={status.id} value={status.id}>{status.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Tag color={fleetData?.statuses?.name === 'Active' ? 'green' : 'processing'}>
                                    {fleetData?.statuses?.name}
                                </Tag>
                            )}
                        </div>
                    </div>

                    <div style={{ gridColumn: '1 / -1' }}>
                        <Text strong>Tags</Text>
                        <div style={{ marginTop: '5px' }}>
                            {editMode ? (
                                <Form.Item name={'tags'} rules={[{ required: true }]} style={{ marginBottom: '0' }}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder="Select or add tags"
                                        onChange={(value) => setFleetData({ ...fleetData, tags: value })}
                                        value={fleetData.tags}
                                    >
                                        {rawFleetData.tags.map((tag) => (
                                            <Option key={tag.id} value={tag.id}>{tag.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            ) : (
                                <div>
                                    {fleetData?.tags?.map(tagId => (
                                        <Tag key={tagId}>{rawFleetData.tags?.find(tag => tag.id === tagId)?.name}</Tag>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default FleetProfile;
