import React, { useState } from 'react';
import { Card, List, Tag, Avatar, Button, Space, Input, Tooltip } from 'antd';
import {
  SettingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const { Search } = Input;

const services = [
  { name: 'Freight Delivery', status: 'Active', icon: <CheckCircleOutlined />, description: 'Efficient freight delivery services across multiple regions.' },
  { name: 'Warehousing', status: 'Active', icon: <SettingOutlined />, description: 'Secure and scalable warehousing solutions.' },
  { name: 'Last Mile Delivery', status: 'Inactive', icon: <ClockCircleOutlined />, description: 'Timely last mile delivery ensuring customer satisfaction.' },
  { name: 'Cross-border Logistics', status: 'Under Review', icon: <CloseCircleOutlined />, description: 'Comprehensive cross-border transportation services.' },
  { name: 'Express Shipping', status: 'Pending', icon: <ClockCircleOutlined />, description: 'Rapid shipping services for urgent deliveries.' },
  { name: 'Bulk Transport', status: 'Active', icon: <CheckCircleOutlined />, description: 'Reliable bulk transport solutions for large consignments.' },
];

const statusColors = {
  Active: 'green',
  Inactive: 'volcano',
  'Under Review': 'geekblue',
  Pending: 'orange',
};

function ServiceOverview() {
  const [filteredServices, setFilteredServices] = useState(services);

  const onSearch = (value) => {
    const filtered = services.filter((service) =>
      service.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  const handleEdit = (service) => {
    // Implement edit functionality or navigation here
    console.log('Edit service:', service);
  };

  const handleViewDetails = (service) => {
    // Implement view details functionality or navigation here
    console.log('View details for service:', service);
  };

  return (
    <Card
      title="Service Overview"
      extra={
        <Search
          placeholder="Search Services"
          allowClear
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      }
      bordered
    >
      <List
        itemLayout="horizontal"
        dataSource={filteredServices}
        locale={{ emptyText: 'No services found.' }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Tooltip title="Edit Service" key="edit">
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(item)}
                />
              </Tooltip>,
              <Tooltip title="View Details" key="view">
                <Button
                  type="link"
                  icon={<EyeOutlined />}
                  onClick={() => handleViewDetails(item)}
                />
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar style={{ backgroundColor: statusColors[item.status] }}>
                  {item.icon}
                </Avatar>
              }
              title={
                <Space>
                  <strong>{item.name}</strong>
                  <Tag color={statusColors[item.status]}>{item.status}</Tag>
                </Space>
              }
              description={item.description}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default ServiceOverview;
